import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton } from "components/misc/Buttons.js";
import ContactUsImage from "images/bc/contact-us.svg";
import { Container } from "components/misc/Layouts";
import * as moment from "moment";
import {
  DATE_TIME_FORMAT,
  DISABLED_HOURS,
} from "booking-campus/helpers/constants";
import { DatePicker, Select, Form as AntForm, Input as AntInput } from "antd";
import { isCorrectPhoneNumber } from "helpers/utils";
const { Option } = Select;
const { Item } = AntForm;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-10 lg:mb-16`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-1/2 flex-shrink-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-1/2 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);
const ImageBackground = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-no-repeat bg-center h-full lg:mt-12 hidden xl:block`,
  css`
    background-position: 2rem center;
  `,
]);
const Image = tw.img`block xl:hidden`;
const TextContent = tw.div`lg:py-9 text-center md:text-left`;
const Subheading = tw(
  SubheadingBase
)`text-center text-lg md:text-left text-bc-neutral2`;
const Heading = tw(
  SectionHeading
)`font-bold text-bc-neutral1 mt-8 text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight max-w-xl`;
const Description = tw.p`text-bc-neutral1 mt-8 text-center md:text-left text-sm md:text-base lg:text-xl font-medium leading-relaxed max-w-xl whitespace-pre-line`;
const Form = tw(AntForm)`mt-6 text-sm flex flex-col max-w-xl mx-auto md:mx-0`;
const FormControl = styled(Item)`
  ${tw`w-full mt-8 mb-0 first:mt-0`}
  input, textarea, .ant-picker {
    ${tw`w-full`}
  }
`;
const Input = styled(AntInput)`
  ${tw`py-3 px-0 bg-transparent! rounded-none text-bc-neutral1 text-base lg:text-xl border-t-0! border-l-0! border-r-0! border-b border-bc-neutral3! hocus:border-bc-neutral1 py-3 focus:outline-none font-medium transition duration-300`}
  ${css`
    box-shadow: none !important;
  `}
`;
const Textarea = styled(Input).attrs({ as: "textarea" })``;
const SingleSelect = styled(Select)`
  ${tw`py-3 focus:outline-none font-medium transition duration-300 border-t-0! border-l-0! border-r-0! border-b border-gray-400 hocus:border-gray-lightest`}
  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: none !important;
    }
  }
  .ant-select-selector {
    ${tw`bg-transparent! pl-0! border-0! hocus:border-0! text-left`}
    .ant-select-selection-search {
      input {
        line-height: 2rem;
      }
    }
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      ${tw`px-0! text-base lg:text-xl text-gray-400`}
    }
  }
  .ant-select-arrow {
    ${tw`px-0! text-gray-400 right-0`}
  }
`;
const DateTimePicker = styled(DatePicker)`
  ${tw`bg-transparent! py-3 px-0 focus:outline-none font-medium transition duration-300 border-t-0! border-l-0! border-r-0! border-b border-gray-400! hocus:border-gray-lightest!`}
  &.ant-picker-focused {
    box-shadow: none;
  }
  input,
  .ant-picker-suffix .anticon {
    ${tw`text-base lg:text-xl text-gray-400`}
  }
`;
const SubmitButton = tw(PrimaryButton)`inline-block mt-14 md:mr-auto`;

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to get in touch with us.</>,
  description = `
                  The Heron: (020) - 7638 - 6668
                  Monday - Friday: 12.00 - 15.00 & 17.00 - 22.00
                  Saturday: 17.00 - 22.00
                  Email: theheron@phamsushi.com

                  Whitecross: (020) - 7251 - 6336
                  Monday - Friday: 12.00 - 21.00
                  Saturday: 17.00 - 21.00
                  Email: phamsushi@aol.com

                  Takeaway at Whitecross: (020) - 7253 - 8658 | (020) - 7251 - 6336
                  Monday - Friday: 12.00 - 21.00
                  Saturday: 17.00 - 21.00
                  Email: phamsushi@aol.com
                `,
  submitButtonText = "Contact Us",
  textOnLeft = true,
  imageSrc = ContactUsImage,
  formCss = null,
  fields = [
    {
      type: "email",
      name: "email",
      placeholder: "Your Email Address",
    },
    {
      type: "text",
      name: "name",
      placeholder: "Full Name",
    },
    {
      type: "text",
      name: "subject",
      placeholder: "Subject",
    },
    {
      type: "textarea",
      name: "message",
      placeholder: "Your Message Here",
    },
  ],
  onSubmit = (value) => {
    console.log("form submit", value);
  },
  isLoading = false,
}) => {
  const [formValue, setFormValue] = useState({});
  const handleChange = (name, value) => {
    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };
  const disabledDateTime = () => ({
    disabledHours: () => DISABLED_HOURS,
  });
  const handleSubmit = () => {
    onSubmit(formValue);
  };
  const getFieldRules = (field) => {
    const rules = [];
    if (field.required) {
      rules.push({
        required: field.required,
        message: "This field is required",
      });
    }
    if (field.type === "email") {
      rules.push({ type: "email", message: "Invalid email" });
    }
    if (field.type === "phone") {
      rules.push({
        validator: (_, value) =>
          !value || isCorrectPhoneNumber(value)
            ? Promise.resolve()
            : Promise.reject(new Error("Invalid phone number")),
        message: "Invalid phone number",
      });
    }
    return rules;
  };
  const renderField = (field) => {
    let fieldChild = <></>;
    switch (field.type) {
      case "email":
      case "phone":
      case "text":
      case "number":
        fieldChild = (
          <Input
            onChange={(event) => handleChange(field.name, event.target.value)}
            {...field}
          />
        );
        break;
      case "textarea":
        fieldChild = (
          <Textarea
            onChange={(event) => handleChange(field.name, event.target.value)}
            {...field}
            rows={5}
          />
        );
        break;
      case "select":
        fieldChild = (
          <SingleSelect
            onChange={(value) => handleChange(field.name, value)}
            {...field}
          >
            {field.options.map((obj) => (
              <Option key={obj}>{obj}</Option>
            ))}
          </SingleSelect>
        );
        break;
      case "datetime":
        fieldChild = (
          <DateTimePicker
            showTime={{
              format: "HH",
              defaultValue: moment("12:00:00", "HH:mm:ss"),
            }}
            showNow={false}
            format={DATE_TIME_FORMAT}
            onChange={(value, dateString) =>
              handleChange(field.name, dateString)
            }
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
            inputReadOnly={true}
            allowClear={false}
            {...field}
          />
        );
        break;
      default:
        break;
    }
    return (
      <FormControl
        key={field.name}
        name={field.name}
        rules={getFieldRules(field)}
      >
        {fieldChild}
      </FormControl>
    );
  };
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <ImageBackground imageSrc={imageSrc} />
          <Image src={imageSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form css={formCss} onFinish={handleSubmit} autoComplete="off">
              {fields && fields.map((field) => renderField(field))}
              <SubmitButton type="submit" disabled={isLoading}>
                {submitButtonText}
              </SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
