import { Form } from "antd";
import { SubmitButton } from "./YourAddressForm";
import { useEffect, useState } from "react";
import { isCorrectPhoneNumber } from "helpers/utils";
import { FormItem, InputField } from "booking-campus/base/Form";

export default ({ initialValues, onSubmit }) => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(true);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values);
    } catch (errorInfo) {}
  };

  return (
    <Form layout="vertical" form={form} initialValues={initialValues}>
      <FormItem
        label="Business name"
        name="businessName"
        rules={[{ required: true, message: "Please input business name" }]}
      >
        <InputField placeholder="Enter business name" />
      </FormItem>
      <FormItem
        label="Your Name"
        name="yourName"
        rules={[{ required: true, message: "Please input your name" }]}
      >
        <InputField placeholder="Enter your name" />
      </FormItem>
      <FormItem
        label="Phone number"
        name="phoneNumber"
        rules={[
          { required: true, message: "Please input phone number" },
          {
            validator: (_, value) =>
            !value || isCorrectPhoneNumber(value)
                ? Promise.resolve()
                : Promise.reject(new Error("Invalid phone number")),
            message: "Invalid phone number",
          },
        ]}
      >
        <InputField placeholder="Enter phone number" />
      </FormItem>
      <FormItem
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Please input email" },
          { type: "email", message: "Invalid email" },
        ]}
      >
        <InputField placeholder="johndoe@email.com" />
      </FormItem>
      <FormItem>
        <SubmitButton onClick={handleSubmit} disabled={!submittable}>
          Continue
        </SubmitButton>
      </FormItem>
    </Form>
  );
};
