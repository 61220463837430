import { Affix, Form } from "antd";
import { SubmitButton } from "./YourAddressForm";
import tw from "twin.macro";
import PaymentsImage from "images/bc/payment.png";
import PaymentsImageMobile from "images/bc/payment-m.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RadioGroup from "booking-campus/base/RadioGroup";
import { FormItem, InputField } from "booking-campus/base/Form";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  CURRENCY,
  CardConfirmStatus,
  DurationType,
  STRIPE_CARD_OPTIONS,
  STRIPE_ELEMENTS_OPTIONS,
  TransactionStatus,
} from "booking-campus/helpers/constants";
import { createPayment } from "booking-campus/services/payment.service";
import PaymentMethodField from "./PaymentMethodField";
import { formatCurrency } from "booking-campus/helpers/utils";
import { tz } from "moment-timezone";

const { REACT_APP_PARTY_ID } = process.env;

const SummarySection = tw.div`px-4 py-1 border border-bc-neutral4 rounded`;
const SummaryTable = styled.table`
  ${tw`w-full`}
  tr {
    td,
    th {
      ${tw`py-2 text-left align-middle`}
      &:last-child {
        ${tw`text-right`}
      }
    }
    th {
      ${tw`text-xs text-bc-neutral3 font-normal`}
    }
    td {
      ${tw`border-t border-bc-neutral4 border-dashed`}
      .name-text {
        ${tw`capitalize`}
      }
      .sub-text {
        ${tw`text-sm text-bc-neutral2`}
      }
      &.price {
        ${tw`text-lg font-bold`}
      }
      &.text-right {
        ${tw`text-right`}
      }
    }
  }
`;
const CardSection = tw.div`my-4 pt-2`;

const Image = tw.img`w-full`;
const MessageWrapper = styled.div`
  ${tw`text-justify text-bc-neutral3 mt-3`}
  P {
    ${tw`text-xs mb-1`}
  }
`;
const StyledLink = tw(Link)`text-bc-primary1`;
export const SectionTitle = tw.p`font-bold text-lg mb-3`;
const AddressSection = tw.div`border-t border-b border-bc-neutral4 pt-6 mb-7`;
const AddressReadOnlyBox = tw.div`bg-bc-neutral5 p-4 mb-4 md:mb-0`;
const AddressItem = styled.div`
  ${tw`text-base`}
  &:not(:first-child) {
    ${tw`mt-5`}
  }
`;
const AddressLabel = tw.label`text-sm text-bc-neutral2`;
const ButtonWrapper = tw.div`bg-white`;

export default ({
  initialValues,
  onSubmit,
  billingStart,
  selectedPlan,
  affixTarget,
}) => {
  const { plan, duration } = selectedPlan;
  const { address1, address2, city, postcode } = initialValues;
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(true);
  const [billingAddressType, setBillingAddressType] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [cardError, setCardError] = useState();
  const [paymentMethodRef, setPaymentMethodRef] = useState();
  const [paymentTransaction, setPaymentTransaction] = useState();
  const billingStartStr = billingStart.format("D MMM yyyy");

  useEffect(() => {
    setCardError();
    setTimeout(() => {
      form
        .validateFields({ validateOnly: true })
        .then((value) => {
          setSubmittable(true);
        })
        .catch((error) => {
          setSubmittable(false);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleBillingAddressTypeChange = (e) => {
    setBillingAddressType(e.target.value);
  };

  const handleCardChange = (event) => {
    setPaymentMethodRef();
    setPaymentTransaction();
  };

  const cardConfirm = async (clientSecret, cardElement) => {
    try {
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: { name: values.cardholderName },
          },
        }
      );

      if (paymentIntent?.status === CardConfirmStatus.SUCCEEDED) {
        return true;
      } else if (error) {
        handleCardChange();
        setCardError(error.message);
        return false;
      }
      // else if (last_payment_error) {
      //   setCardError(last_payment_error.message);
      //   return false;
      // }
    } catch (error) {
      setCardError(error.message);
      return false;
    }
  };

  const handleCreatePayment = async (paymentMethodId, cardElement, address) => {
    if (
      !paymentMethodId ||
      !cardElement ||
      !plan ||
      !duration ||
      !initialValues ||
      !billingStart
    ) {
      return false;
    }
    if (paymentTransaction) {
      return true;
    }
    try {
      const { price, name } = plan;
      const { name: durationName } = duration;
      const { yourName, phoneNumber, email, businessName } = initialValues;
      let endTime = billingStart.clone();
      switch (durationName) {
        case DurationType.MONTHLY:
          endTime = endTime.add(1, "months");
          break;
        case DurationType.YEARLY:
          endTime = endTime.add(1, "years");
          break;
        default:
          break;
      }
      const timezone = tz.guess(true);
      const paymentData = {
        product_name: `${name} - ${durationName} Subscribe`,
        product_price: price[durationName],
        payment_amount_pay_now: price[durationName] || 0,
        payment_amount_pay_later: 0,
        currency: CURRENCY,
        customer_full_name: yourName,
        customer_phone_number: phoneNumber,
        customer_email: email,
        notes: `
        Plan: ${name}
        Plan Duration: ${durationName}
        Plan Price: ${formatCurrency(price[durationName])}
        
        Paid: ${formatCurrency(price[durationName])}
        Remaining Payment: 0  
        
        Customer Business Name: ${businessName}
        Customer Name: ${yourName}
        Phone Number: ${phoneNumber}
        Email: ${email}
        Address: ${address}
        `,
        start_time: billingStart.valueOf(),
        end_time: endTime.valueOf(),
        tzinfo: timezone,
        location: timezone.split("/")[1],
        verification_token: "",
        party_id: REACT_APP_PARTY_ID,
        location_id: "",
        provider: "STRIPE",
        source_id: paymentMethodId,
      };

      const { data } = await createPayment(paymentData);
      setPaymentTransaction(data);

      // handle card authentication
      if (data.payment_status === TransactionStatus.COMPLETED) {
        // No authentication
        return true;
      } else if (
        data.payment_status === TransactionStatus.REQUIRES_CONFIRMATION ||
        data.payment_status === TransactionStatus.PENDING
      ) {
        // Authentication required
        return await cardConfirm(data.external_id, cardElement);
      }
    } catch (error) {
      setCardError(error.message);
      return false;
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();
      if (!stripe || !elements) {
        return;
      }
      elements.update(STRIPE_ELEMENTS_OPTIONS);
      const cardElement = elements.getElement(CardElement);
      let paymentMethodResult = paymentMethodRef;
      if (!paymentMethodRef) {
        paymentMethodResult = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: { name: values.cardholderName },
        });
        setPaymentMethodRef(paymentMethodResult);
      }

      const { paymentMethod, error } = paymentMethodResult;
      if (error) {
        setCardError(error.message);
      } else {
        const { billAddress1, billAddress2, billCity, billPostcode } = values;
        const addressItems =
          billingAddressType === 2
            ? [billAddress1, billAddress2, billCity, billPostcode]
            : [postcode, address1, address2, city];
        const address = addressItems.filter((item) => item).join(", ");

        const isSuccess = await handleCreatePayment(
          paymentMethod.id,
          cardElement,
          address
        );
        if (isSuccess) {
          onSubmit(values);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setCardError(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <SectionTitle>Order Summary</SectionTitle>
      <SummarySection>
        <SummaryTable>
          <thead>
            <tr>
              <th>Item</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p className="name-text">
                  {plan?.name}
                  {" - "}
                  {duration?.name} Subscribe
                </p>
                <p className="sub-text">Billing starts: {billingStartStr}</p>
              </td>
              <td className="price">
                {formatCurrency(plan?.price[duration?.name])}/
                {duration?.shortName}
              </td>
            </tr>
            <tr>
              <td className={isBrowser && "text-right"}>TOTAL TODAY</td>
              <td className="price">£0.00</td>
            </tr>
          </tbody>
        </SummaryTable>
      </SummarySection>

      <Form layout="vertical" form={form} initialValues={initialValues}>
        <CardSection>
          <SectionTitle>Payment Card</SectionTitle>
          <FormItem
            label="Cardholder name"
            name="cardholderName"
            rules={[
              { required: true, message: "Please input cardholder name" },
            ]}
          >
            <InputField placeholder="Enter cardholder name" />
          </FormItem>
          {/* card element */}
          <FormItem
            label="Card"
            name="cardEvent"
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  !value || value.empty
                    ? Promise.reject(new Error("Please input card detail"))
                    : Promise.resolve(),
                message: "Please input card detail",
              },
              {
                validator: (_, value) =>
                  value && !value.complete && !value.empty
                    ? Promise.reject(new Error("Card number is not correct"))
                    : Promise.resolve(),
                message: "Card number is not correct",
              },
            ]}
          >
            <PaymentMethodField
              options={{
                ...STRIPE_CARD_OPTIONS,
              }}
              onChange={handleCardChange}
              cardError={cardError}
            />
          </FormItem>
          <BrowserView>
            <Image src={PaymentsImage} alt="" />
          </BrowserView>
          <MobileView>
            <Image src={PaymentsImageMobile} alt="" />
          </MobileView>
        </CardSection>

        <AddressSection>
          <SectionTitle>Billing Address</SectionTitle>
          <BrowserView>
            <FormItem>
              <RadioGroup
                options={[
                  {
                    value: 1,
                    label: "Same business address",
                  },
                  {
                    value: 2,
                    label: "Different business address",
                  },
                ]}
                value={billingAddressType}
                onChange={handleBillingAddressTypeChange}
              />
            </FormItem>
          </BrowserView>
          <MobileView>
            <FormItem>
              <RadioGroup
                options={[
                  {
                    value: 1,
                    label: "Same business address",
                  },
                ]}
                value={billingAddressType}
                onChange={handleBillingAddressTypeChange}
              />
            </FormItem>
          </MobileView>
          {billingAddressType === 1 && (
            <AddressReadOnlyBox>
              <AddressItem>
                <AddressLabel>Address line 1</AddressLabel>
                <p>{address1}</p>
              </AddressItem>
              {address2 && (
                <AddressItem>
                  <AddressLabel>Address line 2</AddressLabel>
                  <p>{address2}</p>
                </AddressItem>
              )}
              <AddressItem>
                <AddressLabel>City</AddressLabel>
                <p>{city}</p>
              </AddressItem>
              <AddressItem>
                <AddressLabel>Postcode</AddressLabel>
                <p>{postcode}</p>
              </AddressItem>
            </AddressReadOnlyBox>
          )}
          <MobileView>
            <FormItem>
              <RadioGroup
                options={[
                  {
                    value: 2,
                    label: "Different business address",
                  },
                ]}
                value={billingAddressType}
                onChange={handleBillingAddressTypeChange}
              />
            </FormItem>
          </MobileView>
          {billingAddressType === 2 && (
            <>
              <FormItem
                label="Street address line 1"
                name="billAddress1"
                rules={[
                  {
                    required: billingAddressType !== 1,
                    message: "Please input your address",
                  },
                ]}
              >
                <InputField placeholder="Enter your address" />
              </FormItem>
              <FormItem
                label="Street address line 2 (Optional)"
                name="billAddress2"
              >
                <InputField placeholder="Enter your address" />
              </FormItem>
              <FormItem
                label="City"
                name="billCity"
                rules={[
                  {
                    required: billingAddressType !== 1,
                    message: "Please input your city",
                  },
                ]}
              >
                <InputField placeholder="Enter city" />
              </FormItem>
              <FormItem
                label="Postcode"
                name="billPostcode"
                rules={[
                  {
                    required: billingAddressType !== 1,
                    message: "Please input your postcode",
                  },
                ]}
              >
                <InputField placeholder="Enter postcode" />
              </FormItem>
            </>
          )}
        </AddressSection>
        <Affix
          offsetBottom={isBrowser ? -55 : 0}
          target={affixTarget}
        >
          <ButtonWrapper>
            <SubmitButton
              onClick={handleSubmit}
              disabled={isLoading || !submittable}
            >
              Start Your Trial
            </SubmitButton>
          </ButtonWrapper>
        </Affix>
      </Form>
      <MessageWrapper>
        <p>
          Billing starts on {billingStartStr}, the end of your free trial, and
          will renew automatically every month. Payments won't be refunded for
          partial billing periods. Cancel at any time.
        </p>
        <p>
          By continuing, you verify that you are at least 18 years old and agree
          to, which include details of your right to withdrawal within 14 days.
          No refunds for partial billing periods.
        </p>
        <p>
          By continuing, you agree to{" "}
          <StyledLink to="/terms-of-use" target="_blank">
            Term of Use
          </StyledLink>
          ,{" "}
          <StyledLink to="/terms-of-service" target="_blank">
            Term of Service
          </StyledLink>{" "}
          and{" "}
          <StyledLink to="/privacy-policy" target="_blank">
            Privacy Notice
          </StyledLink>{" "}
          at BookingCampus and{" "}
          <StyledLink
            as="a"
            href="https://stripe.com/gb/privacy"
            target="_blank"
          >
            Privacy & Terms
          </StyledLink>{" "}
          at Stripe to make this purchase.
        </p>
      </MessageWrapper>
    </>
  );
};
