import ReactModalAdapter from "helpers/ReactModalAdapter";
import { ReactComponent as CloseIcon } from "images/bc/icons/x.svg";
import { ReactComponent as ArrowIcon } from "images/bc/icons/back.svg";
import RadioCheckedImage from "images/bc/icons/radio-checked.svg";
import styled from "styled-components";
import tw from "twin.macro";
import YourAddressForm, { SubmitButton } from "./YourAddressForm";
import { useEffect, useState } from "react";
import AboutYouForm from "./AboutYouForm";
import PaymentForm, { SectionTitle } from "./PaymentForm";
import moment from "moment";
import { BrowserView, MobileView } from "react-device-detect";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  TRIAL_DURATIONS,
  TRIAL_DURATION_APPLY,
} from "booking-campus/helpers/constants";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const MobileViewFull = tw(MobileView)`w-full`;
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed flex items-center justify-center inset-0 z-50 bg-black bg-opacity-50 transition-none`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto flex-grow flex-col items-center justify-center h-full py-3 sm:(flex block max-h-[90vh] m-16 h-auto m-4 py-12 rounded-modal) lg:py-20 max-w-screen-modal relative inset-0 bg-white outline-none`}
  }
  .content {
    ${tw`w-full px-5 overflow-auto max-h-[calc(100vh - 4rem)] md:(px-12 max-h-[calc(90vh - 10rem)]) lg:px-16`}
  }
`;
const ModalTitle = tw.h5`font-bold text-lg text-bc-neutral1 pl-16 mb-3 font-normal md:(text-center pl-0 mb-0 font-bold) lg:text-xl xl:text-2xl`;
const ModalDescription = tw.p`text-bc-neutral3 mt-5 md:(text-center mb-4 mt-0)`;
const IconButton = styled.button`
  ${tw`absolute mt-4 md:mt-8 text-black text-opacity-50 hocus:text-opacity-100`}
  svg path {
    ${tw`fill-current`}
  }
`;
const BackButton = tw(IconButton)`top-0 left-0 ml-5 md:ml-8`;
const CloseButton = tw(IconButton)`top-0 right-0 mr-6`;
const FormContainer = styled.div`
  ${tw`py-4 text-base`}
  label {
    ${tw`text-bc-neutral2`}
  }
`;
const XSpace = tw.div`px-0 lg:px-22`;
const SuccessWrapper = tw(XSpace)`text-center`;
const SuccessImage = tw.img`w-12 h-12 mx-auto`;
const SuccessMessage = tw(SectionTitle)`mt-5 mb-7`;

const steps = [
  {
    index: 0,
    title: "Your Business Address",
    description: "Where can clients find you?",
  },
  {
    index: 1,
    title: "About you",
    description: "Tell us more about yourself and your business",
  },
  {
    index: 2,
    title: "Add Payment Card",
  },
  {
    index: 3,
  },
];

export default ({ isOpen = false, toggleModal, selectedPlan = {} }) => {
  const [step, setStep] = useState(steps[0]);
  const [formValues, setFormValues] = useState({});
  const billingStart = moment().add(
    TRIAL_DURATIONS[TRIAL_DURATION_APPLY].days,
    "days"
  );
  const [affixContainer, setAffixContainer] = useState();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const handleNext = (formValues) => {
    if (formValues) {
      setFormValues((prevState) => ({
        ...prevState,
        ...formValues,
      }));
    }
    if (step.index < steps.length - 1) {
      setStep((prevState) => steps[prevState.index + 1]);
    } else {
      handleClose();
    }
  };

  const handleBack = () => {
    if (step.index > 0) {
      setStep((prevState) => steps[prevState.index - 1]);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setStep(steps[0]);
    setFormValues({});
    toggleModal();
  };

  return (
    <Elements stripe={stripePromise}>
      <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={isOpen}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick={true}
      >
        <div>
          <MobileViewFull>
            {step.index < steps.length - 1 && (
              <BackButton onClick={handleBack}>
                <ArrowIcon />
              </BackButton>
            )}
            {step.title && <ModalTitle>{step.title}</ModalTitle>}
          </MobileViewFull>
          <BrowserView>
            {step.index > 0 && step.index < steps.length - 1 && (
              <BackButton onClick={handleBack}>
                <ArrowIcon />
              </BackButton>
            )}
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
          </BrowserView>

          <div className="content" ref={setAffixContainer}>
            <BrowserView>
              {step.title && <ModalTitle>{step.title}</ModalTitle>}
            </BrowserView>
            {step.description && (
              <ModalDescription>{step.description}</ModalDescription>
            )}
            <FormContainer>
              {step.index === 0 && (
                <XSpace>
                  <YourAddressForm
                    onSubmit={handleNext}
                    initialValues={formValues}
                  />
                </XSpace>
              )}
              {step.index === 1 && (
                <XSpace>
                  <AboutYouForm
                    onSubmit={handleNext}
                    initialValues={formValues}
                  />
                </XSpace>
              )}
              {step.index === 2 && (
                <PaymentForm
                  onSubmit={handleNext}
                  initialValues={formValues}
                  billingStart={billingStart}
                  selectedPlan={selectedPlan}
                  affixTarget={() => affixContainer}
                />
              )}
              {step.index === 3 && (
                <SuccessWrapper>
                  <SuccessImage src={RadioCheckedImage} alt="" />
                  <SuccessMessage>Successful Payment</SuccessMessage>
                  <SubmitButton onClick={handleNext}>OK, Got it</SubmitButton>
                </SuccessWrapper>
              )}
            </FormContainer>
          </div>
        </div>
      </StyledModal>
    </Elements>
  );
};
