import tw from "twin.macro";

export const Container = tw.div`relative`;
export const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
export const ContentWithPaddingLg= tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`;
export const ContentWithVerticalPadding = tw.div`py-10`;
export const Content= tw.div`mx-auto px-8 2xl:px-0`;
export const ContentXl= tw(Content)`max-w-screen-xl`;
export const Content3Xl= tw(Content)`max-w-screen-3xl`;
export const Content4Xl= tw(Content)`max-w-screen-4xl`;
