import LayoutBase from "booking-campus/components/LayoutBase";
import TermSection, {
  TermContainer,
  TermTitle,
  TermParagraph,
  CenterParagraph,
  ChildTitle,
} from "booking-campus/components/TermSection";
import { TRIAL_DURATIONS, TRIAL_DURATION_APPLY } from "booking-campus/helpers/constants";

const TermOfService = () => {
  return (
    <LayoutBase>
      <TermContainer>
        <TermTitle>Terms of service</TermTitle>
        <TermSection>
          <TermParagraph>
            Hi! We are BookingCampus. We've worked hard to make our Terms and
            Conditions (<strong>Terms of Service</strong>) easy to read. We've
            summarised key points of our Terms of Service below, but you must
            also read the full version that follows.
            <ul>
              <li>
                BookingCampus provides{" "}
                <a
                  href="bookingCampus.com"
                  target="_blank"
                  className="break-words"
                  rel="noreferrer"
                >
                  BookingCampus.com
                </a>{" "}
                and the BookingCampus app (together, our BookingCampus
                Platform), which allows you to discover and book online with (or
                purchase Products from) spas, salons, beauticians, trainers and
                other businesses. You can buy or book Partner Services
                (including any Products that Partners have for sale) by making
                an Appointment or an Order through the BookingCampus Platform.
              </li>
              <li>
                The Partner Services you can book or buy via BookingCampus are
                sold by our Partners and not by us. We are only responsible for
                arranging and concluding your Appointment or Order, and we have
                been appointed by our Partners to act as their commercial agent
                to do so.
              </li>
              <li>
                If you pay for Partner Services using the BookingCampus Payment
                Services, we may collect and receive your payment on behalf of
                the relevant Partner in our capacity as their commercial agent.
                When we receive your payment, this will discharge your debt to
                the Partner for the Partner Services.
              </li>
              <li>
                The contract for the Partner Services is directly between you
                and the relevant Partner. We are not responsible for the Partner
                Services you receive from our Partners. However, we would like
                to know if you are less than happy with any Partner. You can
                contact us at{" "}
                <a
                  href="mailto:info@bookingcampus.com"
                  className="break-words"
                  rel="noreferrer"
                >
                  info@bookingcampus.com
                </a>
                , and we'll see if there's anything we can do to help.
              </li>
              <li>
                If you make an Appointment or Order, you agree to a Partner
                Contract (including any Partner Terms of Sale) with the relevant
                Partner. BookingCampus is not part of this agreement. Please
                thoroughly check all details and any restrictions relating to an
                Appointment or Order before confirming it.
              </li>
              <li>
                BookingCampus may deactivate your BookingCampus Account if you
                breach any part of these Terms of Service or our{" "}
                <a
                  href="/terms-of-use"
                  target="_blank"
                  className="break-words"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
                , or if you act in a way that is inappropriate, abusive or
                otherwise unacceptable towards a Partner or our staff.
              </li>
              <li>
                If you would like to reschedule or cancel an Appointment or
                Order, or return or exchange a Product, first check these Terms
                of Service and any Partner Contract to see if you are able to do
                so. If you are, you can do this through:
                <ul>
                  <li>
                    submitting a request through the BookingCampus Platform,
                  </li>
                  <li>emailing or calling the Partner directly, or</li>
                  <li>
                    emailing our support team at{" "}
                    <a
                      href="mailto:info@bookingcampus.com"
                      className="break-words"
                      rel="noreferrer"
                    >
                      info@bookingcampus.com
                    </a>
                    .
                  </li>
                </ul>
              </li>
            </ul>
            If you want to talk to us, please get in touch with our team and
            we'd be happy to help at{" "}
            <a
              href="mailto:info@bookingcampus.com"
              className="break-words"
              rel="noreferrer"
            >
              info@bookingcampus.com
            </a>
            .
          </TermParagraph>
        </TermSection>
        <TermSection title="BookingCampus  Terms of service – full version">
          <TermParagraph>
            We are BookingCampus Limited, a company incorporated registered in
            England under company number 15062307, with registered office
            address in 164 Long Lane, London, England, N3 2HX, United Kingdom ("
            <strong>BookingCampus</strong>", "<strong>we</strong>", "
            <strong>us</strong>" and "<strong>our</strong>"). These are Terms of
            Service and Conditions ("<strong>Terms of Service</strong>") between
            you and BookingCampus for the use of the BookingCampus Platform (as
            defined below).
          </TermParagraph>
          <TermParagraph>
            These Terms of Service apply to each booking you make through the
            BookingCampus Platform (as defined below), including our online
            booking services or ordering any products.
          </TermParagraph>
          <CenterParagraph>
            Please read these Terms of Service carefully before you use the
            BookingCampus Appointment Services. We recommend that you save or
            print a copy of these Terms of Service for future reference.
          </CenterParagraph>
          <TermParagraph>
            We reserve the right to change these Terms of Service from time to
            time by changing them on this page. Any changes will apply to your
            next Appointment/Order so please check this page regularly. If you
            do not agree to any changes, you should not make an
            Appointment/Order.
          </TermParagraph>
          <TermParagraph>
            Your use of our Site is also subject to our{" "}
            <a
              href="/terms-of-use"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              Terms of Use
            </a>
            . We describe how we use your personal information in our{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </TermParagraph>
        </TermSection>
        <TermSection title="Key terms">
          <TermParagraph>
            When we use capitalised words or phrases in these Terms of Service,
            they have the following meanings:
          </TermParagraph>
          <TermParagraph>
            "<strong>Appointment</strong>" means any appointment booking for one
            or more services from a Partner made through the BookingCampus
            Platform, including as updated, re-scheduled or cancelled;
          </TermParagraph>
          <TermParagraph>
            "<strong>Cancellation Policy</strong>" means a policy that is
            included in the Partner Terms of Sale of an Appointment whereby a
            Partner may charge you (through the BookingCampus Payment Services)
            a certain fee for a cancelled Appointment, which may be up to 100%
            of the value of the Appointment;
          </TermParagraph>
          <TermParagraph>
            "<strong>Client</strong>" and "<strong>you</strong>" means you, the
            user of the BookingCampus Services or the purchaser of any Partner
            Services;
          </TermParagraph>
          <TermParagraph>
            "<strong>Confirmation</strong>" means a written confirmation that we
            provide to you regarding an Appointment or Order, via email or the
            BookingCampus Platform;
          </TermParagraph>
          <TermParagraph>
            "<strong>Deposit Policy</strong>" means a policy that is included in
            the Partner Terms of Sale whereby a Partner may request that you
            (through the BookingCampus Payment Services) purchase a Partner
            Credit at the time you make such Appointment;
          </TermParagraph>
          <TermParagraph>
            "<strong>BookingCampus Account</strong>" means an account you create
            on the BookingCampus Platform, containing information such as your
            name, Appointment and order history, and contact information;
          </TermParagraph>
          <TermParagraph>
            "<strong>BookingCampus Appointment Services</strong>" means all
            appointment booking services, including creating, updating,
            rescheduling and cancelling appointments, provided through the
            BookingCampus Platform;
          </TermParagraph>
          <TermParagraph>
            "<strong>BookingCampus Payment Services</strong>" means the online
            and in-app payment facility available for select Partners on the
            BookingCampus Platform;
          </TermParagraph>
          <TermParagraph>
            "<strong>BookingCampus Platform</strong>" means, together:{" "}
            <strong>(a)</strong> the website{" "}
            <a
              href="bookingCampus.com"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              BookingCampus.com
            </a>
            , accessible through any web browser (our "<strong>Site</strong>"),{" "}
            <strong>(b)</strong> our mobile application published on the Apple
            Store and Google Play Store and downloaded and installed by you on
            your device (our "<strong>App</strong>"), and <strong>(c)</strong>{" "}
            the web interface owned and provided by BookingCampus, which a
            Partner may embed on their own website or social media channels (the
            "<strong>BookingCampus Widget</strong>");
          </TermParagraph>
          <TermParagraph>
            "<strong>BookingCampus Services</strong>" means all information,
            recommendations and/or services provided by BookingCampus to you by
            means of your use of the BookingCampus Platform, including the
            BookingCampus Platform, BookingCampus Appointment Services and
            BookingCampus Payment Services, and Partner Profiles;
          </TermParagraph>
          <TermParagraph>
            "<strong>No Show Policy</strong>" means a policy that is included in
            the Partner Terms of Sale of an Appointment whereby a Partner may
            charge you (through the BookingCampus Payment Services) a certain
            fee for a failing to show up to a Partner's place of business at the
            time indicated on the Appointment, which may be up to 100% of the
            value of the Appointment;
          </TermParagraph>
          <TermParagraph>
            "<strong>Order</strong>" means any orders for products ("
            <strong>Products</strong>") from a Partner through their store on
            the BookingCampus Platform;
          </TermParagraph>
          <TermParagraph>
            "<strong>Partner Contract</strong>" means any contract between you
            and the relevant Partner for their Partner Services which you book
            through the BookingCampus Platform, including any Partner Terms of
            Sale (as provided to you prior to confirming your
            Appointment/Order);
          </TermParagraph>
          <TermParagraph>
            “<strong>Partner Credit</strong>” means a credit which is purchased
            via the BookingCampus Services by the Client for the purpose of
            providing a pre-payment to a Partner in respect of a Partner
            Service.
          </TermParagraph>
          <TermParagraph>
            "<strong>Partner Profile</strong>" means a Partner's webpage(s) on
            the BookingCampus Platform, which may include information about
            their Partner Services and, if applicable, their Partner store;
          </TermParagraph>
          <TermParagraph>
            "<strong>Partner Services</strong>" means any products, goods and/or
            services of a Partner offered for purchase or booking on the
            BookingCampus Platform, including fulfilling any Appointment,
            Voucher or Order;
          </TermParagraph>
          <TermParagraph>
            "<strong>Partner Terms of Sale</strong>" mean the date, time, price,
            Partner Services, discount, location, and any other information or
            terms and conditions (including any Deposit Policy, Cancellation
            Policy, No Show Policy or a return and refunds policy ) provided
            through the BookingCampus Platform at the time that an Appointment
            is made by the Client.
          </TermParagraph>
          <TermParagraph>
            "<strong>Partner Voucher</strong>" means a voucher (with details of
            a Partner offer and a unique security code) issued by the Partner
            via the BookingCampus Platform which, when presented to the relevant
            Partner in physical or electronic form, entitles the voucher holder
            to receive the Partner offer from the Partner, subject to any
            Partner Contract.
          </TermParagraph>
          <TermParagraph>
            "<strong>Partner</strong>" means a selected third-party provider of
            goods and services, who offers their goods and services for sale on
            the BookingCampus Platform;
          </TermParagraph>
          <TermParagraph>
            "<strong>Pay In-Store Service</strong>" means where an
            Appointment/Order is made on the BookingCampus Platform but payment
            is made at the Partner's premises (and not made online using the
            BookingCampus Payment Services);
          </TermParagraph>
          <TermParagraph>
            "<strong>Payment Processor</strong>" means third-party payment
            processors supporting BookingCampus with the provision of the
            BookingCampus Payment Services;
          </TermParagraph>
          <TermParagraph>
            "<strong>Promo Codes</strong>" means a promotional code issued by a
            Partner which can be used when purchasing Partner Services, always
            subject to the relevant promotion terms.
          </TermParagraph>
        </TermSection>
        <TermSection title="Our services">
          <TermParagraph>
            BookingCampus offers a way to connect you to our Partners, who offer
            a range of Products and services in the beauty and wellness space,
            such as hair, beauty, training and therapy services. The
            BookingCampus Platform allows you to make an Appointment for a
            Partner Service, an Order for a Product from a Partner and directly
            pay for your Services and Orders. BookingCampus does not operate any
            Partner, provide any Partner Services, or fulfil any Orders for
            Products.
          </TermParagraph>
          <TermParagraph>
            <strong>
              By using the BookingCampus Services you enter into a contract with
              BookingCampus under these Terms of Service. However, your contract
              for the Partner Services is only between you and the Partner.
            </strong>
          </TermParagraph>
          <TermParagraph>
            BookingCampus acts only as an intermediary between you and the
            Partners, including acting as the agent for each Partner to arrange
            and conclude your Appointment/Order.
          </TermParagraph>
          <TermParagraph>
            When you pay for an Appointment/Order on the BookingCampus Platform,
            we receive payment as a commercial agent on behalf of the relevant
            Partner and this discharges your debt to the Partner for that
            Appointment/Order. This doesn't apply to any Pay In-Store Service.
          </TermParagraph>
        </TermSection>
        <TermSection title="Our partners' services ">
          <TermParagraph>
            <strong>
              If you use the BookingCampus Platform to book or order any Partner
              Services or Products, you also enter into a Partner Contract with
              the relevant Partner, including any Partner Terms of Sale.
            </strong>
          </TermParagraph>
          <TermParagraph>
            Partners' provision of Partner Services and fulfilling any
            Appointment/Order is subject to the Partner Contract. BookingCampus
            is not a party to the Partner Contract you have with the Partner.
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Our Partner's responsibilities</ChildTitle>
            <TermParagraph>
              Our Partners are required to provide Partner Services as set out
              in the relevant Partner Contract (including any Partner Terms of
              Sale).
            </TermParagraph>
            <TermParagraph>
              We require our Partners to ensure that all information provided by
              them for the BookingCampus Platform is accurate, complete and not
              misleading in any way. Nonetheless, we cannot verify the
              information which Partners provide to us. It is each Partner's
              responsibility to ensure that all of its Partner Services listed
              on the BookingCampus Platform are available and accurately
              described. The descriptions and/or images of the Partner Services
              on the BookingCampus Platform are for illustrative purposes only,
              and actual Partner Services may vary from those descriptions
              and/or images.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Your rights</ChildTitle>
            If you are a consumer, you have legal rights in relation to services
            that are not performed with reasonable care and skill or are
            otherwise not as described. Advice about your legal rights is
            available from your local Citizens' Advice Bureau or trading
            standards office. Nothing in these Terms of Service will affect
            these legal rights.
          </TermParagraph>
        </TermSection>
        <TermSection title="Using the BookingCampus platform">
          <TermParagraph>
            In order to make any Appointment/Order through the BookingCampus
            Platform you may check out as a guest, or you may create a
            BookingCampus Account by signing-up with BookingCampus and providing
            certain information, including your name, mobile telephone number
            and payment information, and any password you set.
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Providing accurate information</ChildTitle>
            <TermParagraph>
              You must provide accurate and complete information to
              BookingCampus, and keep this information up to date through the
              BookingCampus Platform. BookingCampus may verify the information
              that you provide, and may refuse use of the BookingCampus Services
              or BookingCampus Platform without providing reasons.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Using BookingCampus responsibly</ChildTitle>
            To get the make most of your use of BookingCampus, you can
            <ul>
              <li>
                <strong>Contact Partners directly</strong> – each Partner
                Profile will include their contact information, so you can
                easily get in touch with them. You can also get in touch with
                our support team at{" "}
                <a
                  href="mailto:info@bookingcampus.com"
                  className="break-words"
                  rel="noreferrer"
                >
                  info@bookingcampus.com
                </a>
                .
              </li>
              <li>
                <strong>Read the Partner Contract</strong> – Our Partners may
                add specific terms (for example about deposits, cancellations,
                refunds, returns, taxes or extra duties). Look for links to any
                specific Partner Contract terms, and read these carefully.
              </li>
              <li>
                <strong>Check out safely</strong> – our BookingCampus Payment
                Services are set up to protection your online payment experience
                Look for the S’ in the beginning of the checkout link (as in
                https://) to check if it is secure.
              </li>
              <li>
                <strong>Research independently</strong> – check out reviews (for
                example, on Google or other trusted review sites) as well as the
                reviews on BookingCampus to see what other people thought of a
                Partner's products or services.
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>
              Providing any health or other relevant information
            </ChildTitle>
            <TermParagraph>
              It is your (or the person receiving the Partner Services) sole
              responsibility to communicate in advance any medical or
              health-related conditions and/or special needs to the Partner that
              might affect or be affected by any Partner Services (for example
              without limitation, allergy information and health matters). This
              includes checking, prior to purchasing any Product, that it is
              suitable for you and requesting further information from the
              Partner selling the Product if required.
            </TermParagraph>
            <TermParagraph>
              If you (or the relevant recipient of the Partner Services) fail to
              disclose any such information to the applicable Partner, or fail
              to check a Product's suitability (including checking with the
              relevant Partner), neither BookingCampus nor the relevant Partner
              shall be liable to you (or the recipient of the Partner Services)
              for any injury, loss or damages resulting from the Partner
              Services that could reasonably have been avoided if you (or the
              recipient of the Partner Services) had disclosed (or checked or
              requested) that information prior to receiving the Partner
              Services.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Service communications</ChildTitle>
            <TermParagraph>
              In connection with your use of the BookingCampus Services,
              BookingCampus may send you electronic service messages. These
              electronic messages may be sent for various reasons, including for
              security purposes (e.g. verifying that your smartphone is
              connected to the phone number you entered upon registration) and
              issues related to an Appointment/Order you placed (e.g. if the
              Partner updates an Appointment, or is out of stock of an item you
              ordered, you may be notified by text message).
            </TermParagraph>
            <TermParagraph>
              By using the BookingCampus Services you agree to receive automated
              text messages from BookingCampus and its Partners at the phone
              number you provided when registering for the service. You further
              agree that these messages may include promotional content as well
              as information about your account and Appointment/Order. Your
              agreement to receive such automated messages is not a condition of
              using the service You may choose to not receive some or all
              automated messages within your personal profile settings.
            </TermParagraph>
            <TermParagraph>
              For information on how we use your personal information, and about
              promotional and marketing messages, please refer to our{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                className="break-words"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </TermParagraph>
          </TermParagraph>
        </TermSection>
        <TermSection title="How to make an appointment / order ">
          <TermParagraph>
            The BookingCampus Platform allows you to select which Partner
            Services you would like to receive and make an Appointment/Order for
            that Partner Service. You may check and correct any errors before
            confirming your Appointment/Order. We will disclose any Partner
            Terms of Sale (including any Deposit Policy, Cancellation Policy or
            No Show Policy) to you prior to your confirmation. Please read and
            check all details of your Appointment/Order and any applicable
            Partner Terms of Sale before confirming your Appointment/Order.
          </TermParagraph>
          <TermParagraph>
            When you make an Appointment/Order, we (as commercial agent of the
            Partner) will send your request to the Partner, and the Partner
            Contract will be formed when we send you a Confirmation. All Partner
            Services shown on the BookingCampus Platform are subject to
            availability. We may accept or reject each Appointment/Order on
            behalf of the Partner. Your Confirmation is your receipt from
            BookingCampus, and will be sent via email or SMS, to the contact
            information provided in your BookingCampus Account, upon confirming
            an Appointment/Order. If you require a tax receipt, you need to
            contact the Partner directly.
          </TermParagraph>
          <TermParagraph>
            When you make an appointment, you will need to arrive at the place
            of business of the Partner in order to receive the Partner Services.
            The place of business will be indicated in the Partner Terms of
            Sale.
          </TermParagraph>
        </TermSection>
        <TermSection title="Vouchers issued by our Partners">
          <TermParagraph>
            Partners may issue Partner Vouchers which can be used for Partner
            Services, or our Partners may allow you to purchase Partner Vouchers
            for later use against their services. The Partner Vouchers are at
            the discretion of the Partner.
          </TermParagraph>
          <TermParagraph>
            If you purchase a Partner Voucher from a Partner, this is subject to
            any Partner Terms of Sale provided to you at the time of purchase.
            Partners' provision and redemption of Partner Vouchers is subject to
            the Partner Contract (including any Partner Terms of Sale).
            BookingCampus is not a party to the Partner Contract you have with
            the Partner, and is not responsible for Partner Vouchers. Unless the
            offer terms state a fixed or minimum period for which an offer will
            be available, it can be withdrawn at any time, unless you have
            already placed an order for an Appointment/Order based on the offer
            and we have sent a confirmation notice.
          </TermParagraph>
        </TermSection>
        <TermSection title="Promo codes issued by our Partners">
          <TermParagraph>
            Promo codes issued by our PartnersPartners may issue Promo Codes,
            which can be used when purchasing Partner Services. The Promo Codes
            are issued at the Partner's discretion and may be withdrawn at any
            time, including if they are used fraudulently, cause technical
            issues or are subject to tampering.
          </TermParagraph>
          <TermParagraph>
            If Promo Codes are made exclusively available and identified as
            being personal to you, you must not share it with any third party.
            If Promo Codes are made publicly available, you may share them with
            any third party. If in the reasonable opinion of the Partner and /
            or BookingCampus, it considers that the Promo Code has been shared
            or misused by you, the Partner and / or BookingCampus can cancel
            such Promo Codes.
          </TermParagraph>
          <TermParagraph>
            The Partner is responsible for setting the terms of any Promo Codes
            and fulfilling the Partner Services. BookingCampus is not
            responsible for any Promo Codes issued by our Partners. For more
            information on Promo Codes, please visit our{" "}
            <a
              href="/"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              blog
            </a>
            .
          </TermParagraph>
        </TermSection>
        <TermSection title="Paying for a Partner service">
          <TermParagraph>
            <ChildTitle>Prices charged by our Partners</ChildTitle>
            <TermParagraph>
              Prices and any delivery or processing charges for any
              Appointment/Order will be as quoted on the BookingCampus Platform.
            </TermParagraph>
            <TermParagraph>
              The final price you pay for an Appointment may be adjusted to
              reflect any additional goods or services you purchase from the
              Partner at the time of receiving the Partner Services.
            </TermParagraph>
            <TermParagraph>
              BookingCampus Partners may also offer discounts that would be
              reflected on the final amount payable.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Tips</ChildTitle>
            <TermParagraph>
              You may wish to tip the Partner for your Appointment. You will be
              given this option via a notification on the BookingCampus Platform
              following your Appointment. Any tips are in addition to the
              purchase price you pay for the Appointment itself, and are
              entirely at your discretion. BookingCampus will collect payment on
              behalf of the Partner, as their limited payment collection agent,
              and payment of the tips shall be considered a direct payment from
              you to the Partner. We'll share your name with the Partner when we
              notify them of the tip. Subject to payment processing fees, the
              Partner will receive 100% of any (tip) payment you chose to make.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Making payments for Partner services</ChildTitle>
            <TermParagraph>
              You agree that you will provide BookingCampus with a valid method
              of payment, and that you will pay for all Partner Services you
              purchase from the Partner. You may pay for Appointments and Orders
              via the BookingCampus Payment Services or directly with the
              Partner as a Pay In-Store Service.
            </TermParagraph>
            <TermParagraph>
              If you choose to use the BookingCampus Payment Services,
              BookingCampus will charge you on behalf of the Partner (as its
              commercial agent) for the total amount of your Appointment/Order
              (including any applicable taxes and additional fees). To complete
              the BookingCampus Payment Services, we use the payment method you
              provide to us and in the currency stated in the Confirmation.
              Currency conversion costs or other charges incurred by you will be
              borne by you in addition to the payment made to us . When
              BookingCampus receives your payment for the Partner Services, this
              will discharge your debt to the relevant Partner for the relevant
              Appointment/Order.
            </TermParagraph>
            <TermParagraph>
              You may have the option to select certain Appointments/Orders as a
              Pay In-Store Service. This means that you may make an
              Appointment/Order without using BookingCampus Payment Services and
              then pay the Partner directly for Partner Services with cash,
              credit card, or another payment instrument accepted by the
              Partner. The Partner has the option to charge the Client's payment
              method saved to the BookingCampus Account at checkout for Pay
              In-Store Services. This option will only appear for Partners which
              enable Pay In-Store Services and may not appear for some Partners.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Payment processor </ChildTitle>
            <TermParagraph>
              BookingCampus uses third-party payment processor (the "
              <strong>Payment Processor</strong>") to link the payment card you
              provide to the BookingCampus Platform. The processing of payments
              or credits, as applicable, in connection with your use of the
              BookingCampus Platform will be subject to the{" "}
              <a
                href="/"
                target="_blank"
                className="break-words"
                rel="noreferrer"
              >
                privacy policies, terms and conditions and other terms of our
                Payment Processor
              </a>{" "}
              and your credit card issuer (in addition to these Terms of
              Service). BookingCampus takes reasonable care to ensure that the
              BookingCampus Payment Services are available and functioning at
              all times, but cannot guarantee continuous, uninterrupted or
              secure access to the BookingCampus Payment Services, nor can we
              guarantee that the facility is virus or error free.
            </TermParagraph>
            <TermParagraph>
              BookingCampus is not responsible for any errors by the Payment
              Processor. Access to the Payment Processing may be occasionally
              restricted to allow for repairs, maintenance or the introduction
              of new facilities or services. We will attempt to provide
              reasonable notice of any scheduled interruptions and will do what
              we can to restore the facility as soon as reasonably possible.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Tax</ChildTitle>
            <TermParagraph>
              The Partner is solely responsible for any indirect taxes (for
              example sales tax, VAT, GST, or service tax) on the total value of
              an Appointment/Order, plus any adjustments for additional goods or
              services sold or discounts applied, where applicable.
            </TermParagraph>
            <TermParagraph>
              Other than VAT or other applicable sales taxes which are included
              in the price shown on the BookingCampus Platform, BookingCampus
              does not charge you taxes on Orders, as the Partner Services are
              provided by the Partner, not by BookingCampus. If you order via
              the BookingCampus Platform, we will provide a tax invoice via the
              BookingCampus Platform. If you order directly via a Partner, the
              Partner shall provide a tax invoice on request.
            </TermParagraph>
            <TermParagraph>
              We add taxes to all Products in an Order, and apply the highest
              applicable Product tax for shipping, where relevant (i.e. if
              Products within an Order have different tax rates, the highest tax
              rate will apply to all Products in that Order).
            </TermParagraph>
          </TermParagraph>
        </TermSection>
        <TermSection title="Appointment changes and cancellations">
          <TermParagraph>
            <ChildTitle>Rescheduling an Appointment </ChildTitle>
            <TermParagraph>
              If you wish to reschedule your Appointment (e.g. time/date) in
              whole or in part after you have received a Confirmation, you may
              do so through the BookingCampus Platform or by contacting the
              relevant Partner directly.
            </TermParagraph>
            <TermParagraph>
              You may only reschedule your Appointment if your Appointment is
              not due to take place within a time frame (e.g. 24 or 48 hours)
              determined by the Partner and set out in the Partner Terms of
              Sale. The interval between when you may reschedule your
              Appointment and your scheduled Appointment start time will be
              determined independently by each Partner, and disclosed to you at
              the time of making your Appointment. You should review and
              understand the Cancellation Policy before confirming your
              appointment on the BookingCampus Platform. Please note that your
              ability to reschedule your Appointment will be subject to the
              Partner's availability during the time you wish to reschedule.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Deposits, cancellation and no show policies</ChildTitle>
            <TermParagraph>
              In addition to your other legal rights, you may in certain
              circumstances have the right to cancel a Partner Contract as set
              out in this section of the Terms of Service.
            </TermParagraph>
            <TermParagraph>
              <strong>Deposits</strong>
            </TermParagraph>
            <TermParagraph>
              Partners may request a deposit in exchange for Partner Credits of
              up to 100% of the value of the Appointment/Order to confirm your
              Appointment/Order if required under the Partner Contract
              (including any Deposit Policy) (a "<strong>Deposit</strong>"). We
              recommend you read the Partner Contract (including any Deposit
              Policy) prior to completing the Appointment/Order.{" "}
              <strong>
                Deposits may be non-refundable. BookingCampus is not responsible
                for any Deposit you make to a Partner.
              </strong>
            </TermParagraph>
            <TermParagraph>
              <strong>Cancellations & no shows</strong>
            </TermParagraph>
            <TermParagraph>
              If you wish to cancel your Appointment, you may do so through
              either the BookingCampus Platform, or by contacting your Partner
              directly. You may only cancel an Appointment through the
              BookingCampus Platform provided that your Appointment is not due
              to take place within a time frame (e.g. 24 or 48 hours) determined
              by the Partner and set out in the Partner Terms of Sale. The
              interval between when you may cancel your Appointment and your
              scheduled Appointment start time will be determined independently
              by each Partner, and disclosed to you at the time of making your
              Appointment as part of the Partner Terms of Sale.
            </TermParagraph>
            <TermParagraph>
              BookingCampus Partners may choose to implement a Cancellation
              Policy, the terms of which will be disclosed to and confirmed by
              you at the time of making your Appointment. If a Partner has such
              a Cancellation Policy, then BookingCampus may automatically charge
              your card an amount determined by the Partner, which may be up to
              the full amount (100%) of the value of the Appointment. If you
              cancel an Appointment beyond the time indicated in the Partner's
              Cancellation Policy, you will not be entitled to any refund unless
              mutually agreed between you and the Partner directly.
            </TermParagraph>
            <TermParagraph>
              Partners may also choose to implement a No Show Policy. If you
              fail to turn up to a Partner's place of business at the time
              indicated in your Appointment, a Partner may choose to exercise
              their No Show Policy, wherein BookingCampus may automatically
              charge your card an amount of up to the full amount (100%) of the
              value of the Appointment.
            </TermParagraph>
            <TermParagraph>
              <strong>
                No refund will be given where a cancellation is attempted within
                the time period specified in the Cancellation Policy of the
                relevant Partner.
              </strong>
            </TermParagraph>
          </TermParagraph>
        </TermSection>
        <TermSection title="Product refunds and cancellations">
          <TermParagraph>
            <ChildTitle>Purchasing products from Partners</ChildTitle>
            <TermParagraph>
              Our Partners can create a Partner store using our services, which
              allows you to buy Products from them directly. BookingCampus does
              not sell the Products available through the BookingCampus
              Platform, our Partners do. When you buy a Product on the
              BookingCampus Platform you buy from the Partner directly, not from
              BookingCampus and the Partner is solely responsible for the
              marketing, advertising, sale, delivery and any after sale care.
            </TermParagraph>
            <TermParagraph>
              If you are not happy with a Product, it is faulty or late you
              should contact the Partner that sold the Product. Set out below in
              this policy are the cancellation and refunds rights offered by
              Partners as a minimum.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Product delivery</ChildTitle>
            <TermParagraph>
              Your Order will be fulfilled by the delivery date set out in the
              Confirmation or, if no delivery date is specified, then within 30
              days of the date of the Confirmation, unless there are exceptional
              circumstances.
            </TermParagraph>
            <TermParagraph>
              Your Order will be delivered to the delivery address you specify
              when placing your Order. Orders cannot be delivered to PO Box or
              similar addresses. Our Partners will decide which countries and
              addresses which they will ship to. Please note that customs or
              import duties may be payable depending on the destination you have
              selected for shipping.
            </TermParagraph>
            <TermParagraph>
              Products within the same Order cannot be delivered to different
              addresses.
            </TermParagraph>
            <TermParagraph>
              If you order Products for delivery outside the country where the
              Partner is located and the Partner agrees to deliver them, they
              may be opened and inspected by customs authorities and may be
              subject to import duties and taxes which are levied when the
              delivery reaches the specified destination. You will be
              responsible for payment of any such import duties and taxes.
              Please note that we have no control over these charges and cannot
              predict their amount. Please contact your local customs office for
              further information before placing your Order.
            </TermParagraph>
            <TermParagraph>
              Please also note that you must comply with all applicable laws and
              regulations of the country for which the Products are destined.
              BookingCampus will not be liable for any breach by you of any such
              laws.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Your UK cancellation rights</ChildTitle>
            <TermParagraph>
              If you are in the United Kingdom:
              <ul>
                <li>
                  You may cancel an Order at any time before your Order is
                  delivered and up to fourteen (14) days afterwards, beginning
                  on the day after you received the Products. In this case, you
                  will receive a full refund of the price paid for the Products
                  (subject to you returning them as set out below).
                </li>
                <li>
                  To cancel an Order, you can inform the Partner by email or
                  telephone giving the Partner your name, address and Order
                  reference; or
                </li>
                <li>
                  You must also return the Products to the Partner within 14
                  days of notifying the Partner of the cancellation, in the same
                  condition in which you receive them (except to the extent
                  reasonably necessary for you to examine them). You have a
                  legal obligation to take reasonable care of the Products while
                  they are in your possession. If you fail to comply with this
                  obligation, the Partner may have a right to claim the cost of
                  any deterioration, up to the price of the Product, from the
                  refund to which you are otherwise entitled.
                </li>
                <li>
                  You will not have any right to cancel an Order for the supply
                  of any Products that have been personalised or made to your
                  own bespoke specifications unless such Products were damaged
                  or faulty when delivered to you or have been incorrectly
                  delivered.
                </li>
                <li>
                  To return a Product, you should package the parcel securely
                  (making sure you include a note of your name, address and any
                  returns slip if provided one inside the parcel) and then
                  return it to the Partner, either by courier or by recorded
                  delivery mail or other form of certified mail, to the address
                  provided. Where a Product being returned is high value the
                  return should be completed using a secure courier.
                </li>
                <li>
                  We advise that you take out enough postal insurance to cover
                  the value of the contents. Please save your proof of posting
                  and tracking information until your refund has been processed.
                  You will be responsible for the cost and risk of returning the
                  items to the Partner, unless a Product is faulty or
                  misdescribed in which case the Partner will be responsible for
                  the cost of return.
                </li>
                <li>
                  Details of the consumer rights described above, and an
                  explanation of how to exercise them, are provided in the
                  Confirmation. Nothing in this section affects your legal
                  rights.
                </li>
              </ul>
            </TermParagraph>
            <TermParagraph>
              If you are outside the United Kingdom, local consumer law may
              apply.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Product returns and refunds</ChildTitle>
            <TermParagraph>
              If you are in the United Kingdom:
              <ul>
                <li>
                  If you cancel an Order between you and a Partner within the
                  fourteen (14) day cooling-off period, the Partner must process
                  the refund due to you as soon as possible and, in any case,
                  within 14 days after the day on which the Partner receives the
                  Products back or, if earlier, the day on which the Partner
                  receives evidence that you have returned the Products to the
                  provided returns address. The Partner will refund the price of
                  the Product in full, including the cost of standard delivery
                  (this may not include the costs of any alternative or premium
                  delivery options). However, you will be responsible for the
                  cost of returning the Product(s) to the Partner (unless
                  otherwise stated in any Partner Terms of Sale).
                </li>
                <li>
                  Where you place an Order and pay for a Product but the Partner
                  does not accept your Order, you will be entitled to a refund
                  for that purchase.
                </li>
                <li>
                  You will usually receive any money refunded to you using the
                  same method you originally used to pay for your purchase.
                </li>
              </ul>
            </TermParagraph>
            <TermParagraph>
              If you are outside the United Kingdom, local consumer law may
              apply.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Faulty products </ChildTitle>
            If any Product you Order is damaged or faulty when delivered to you,
            the Partner may offer a repair, exchange or refund, as appropriate,
            in accordance with your legal rights. If you believe a Product was
            delivered damaged or faulty, you must inform the Partner in writing,
            including your name, delivery address and Order reference. Nothing
            in this section affects your legal rights.
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Need help with a product order?</ChildTitle>
            Please submit any questions you have about your Product to the
            relevant Partner who supplied them to you. Contact details for each
            of the Partners may be found in the Confirmation or the Partner
            Profile within the BookingCampus Platform.
          </TermParagraph>
        </TermSection>
        <TermSection title="Restrictions on access">
          <TermParagraph>
            BookingCampus reserves the right to partially or fully restrict your
            access to the BookingCampus Services or BookingCampus Platform where
            any of the following occur:
            <ul>
              <li>
                you have a history of many cancelled Appointments, no shows,
                refunds, chargebacks, or other transactions that are negative to
                the experience of our Partners;
              </li>
              <li>
                you fail any credit or fraud prevention check provided by the
                BookingCampus Payment Services;
              </li>
              <li>
                we reasonably suspect fraud or money laundering by you or
                someone using your BookingCampus Account;
              </li>
              <li>
                we reasonably suspect the security of your BookingCampus Account
                has been compromised;
              </li>
              <li>
                you behave inappropriately, abusively, or offensively towards
                staff of BookingCampus or employees of a Partner, whether on the
                phone, through email, through communications within the
                BookingCampus Platform, or in person at the place of business of
                a Partner or breach our{" "}
                <a
                  href="/terms-of-use"
                  target="_blank"
                  className="break-words"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
                ;
              </li>
              <li>
                you breach other terms set forth in these Terms of Service,
                including your promises to us; or
              </li>
              <li>
                we believe, in our reasonable and sole discretion, that your
                actions are negatively affecting the quality of BookingCampus
                Services.
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            Further (as Partner Services are sold by our Partners and not by
            BookingCampus), our Partners may impose additional restrictions on
            who may buy and book the Partner Services that they offer. In
            particular, a Partner may:
            <ul>
              <li>restrict your access to their Partner Profile;</li>
              <li>
                prevent you from making an Appointment/Order with Partner
                Services that they offer; and
              </li>
              <li>
                otherwise restrict your ability to interact with that Partner on
                the BookingCampus Platform.
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            These restrictions are determined solely by the Partners, and we are
            unable to provide you with access to these Partner Services.
          </TermParagraph>
        </TermSection>
        <TermSection title="Liability">
          <TermParagraph>
            Where we have been negligent and/or breached a contractual
            obligation to you, we will be liable for any loss or damage you
            suffer as a result, provided that loss and/or damage is foreseeable.
            Loss or damage is foreseeable if it is an obvious consequence of our
            negligence or breach of contract, or would have been considered by
            you and us to be a likely consequence of it at the time you accepted
            these Terms of Service.
          </TermParagraph>
          <TermParagraph>
            We do not accept any liability for the following types of loss,
            whether caused by breach of contract, tort (including negligence) or
            otherwise, even if the loss is foreseeable: loss of income or
            revenue; loss of business; loss of profits; loss of anticipated
            savings; or waste of management or office time.
          </TermParagraph>
          <TermParagraph>
            We do not exclude or limit our liability for death or personal
            injury arising from our negligence, for fraud or fraudulent
            misrepresentation, or for any other liability which cannot be
            excluded or limited under English law.
          </TermParagraph>
          <TermParagraph>
            We are not liable to you for the provision or non-provision of the
            Partner Services and / or Products. The Partner is responsible for
            the fulfilment of all Partner Services and Products under your
            Partner Contract with them.
          </TermParagraph>
        </TermSection>
        <TermSection title="Indemnity">
          <TermParagraph>
            You agree to indemnify and release BookingCampus (and its
            subsidiaries, agents, licensors, managers, and other affiliated
            companies, and their staff members, contractors, agents, officers
            and directors), from and against any and all claims, damages,
            obligations, losses, liabilities, costs or debt, and expenses
            (including but not limited to attorney's fees and costs) arising out
            of or related to:
            <ul>
              <li>
                your use (or misuse) of the BookingCampus Services or
                BookingCampus Platform, including any data or content
                transmitted or received by you;
              </li>
              <li>
                personal information or any other information or content that is
                submitted via your customer account, including without
                limitation misleading, false or inaccurate information;
              </li>
              <li>
                your breach or violation of any term of these Terms of Service
                or any applicable law, rule or regulation;
              </li>
              <li>
                your violation of any rights of any third party, including
                Partners;
              </li>
              <li>negligent or willful misconduct; or</li>
              <li>
                any other party's access and use of the BookingCampus Services
                with your unique username, password or other appropriate
                security code.
              </li>
            </ul>
          </TermParagraph>
        </TermSection>
        <TermSection title="Ending these Terms of service">
          <TermParagraph>
            BookingCampus shall, as soon as reasonably practicable following,
            and in any event within thirty (30) days of, termination of the
            agreement, delete (including by putting beyond practicable use) or
            return to the Client (at the Client's direction) all personal data.
          </TermParagraph>
          <TermParagraph>
            Notwithstanding the foregoing, BookingCampus may retain personal
            data beyond the termination or expiry solely if, and for so long as,
            such personal data must be retained in order to comply with
            applicable law.
          </TermParagraph>
        </TermSection>
        <TermSection title="General">
          <TermParagraph>
            <ChildTitle>Platform fees</ChildTitle>
            Clients' use of the BookingCampus Platform is free of charge.
            BookingCampus reserves the right to introduce a fee for the use of
            the BookingCampus Platform. If BookingCampus decides to introduce
            such a fee, BookingCampus will tell you in advance and allow you to
            continue or stop using the BookingCampus services.
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Assignment</ChildTitle>
            You may not assign your rights under these Terms of Service without
            prior written approval of BookingCampus.
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Notices</ChildTitle>
            BookingCampus may give notice by means of a general notice on the
            BookingCampus Platform, or by email, or post sent by regular mail.
            BookingCampus will only accept notice by email at{" "}
            <a
              href="mailto:info@bookingcampus.com"
              className="break-words"
              rel="noreferrer"
            >
              info@bookingcampus.com
            </a>{" "}
            or by post to attention: BookingCampus.com, BookingCampus Ltd., 164
            Long Lane, London, England, N3 2HX.
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Entire agreement</ChildTitle>
            The Terms of Service (including any referenced documents) form the
            entire agreement between you and BookingCampus and governs your use
            of the BookingCampus Services, superseding any prior version of
            these Terms of Service between you and BookingCampus. Your Partner
            Contract governs the provision of the Partner Services and Products.
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Enforcement & severability</ChildTitle>
            <TermParagraph>
              BookingCampus's failure to exercise or enforce any right or
              provision of the Terms of Service shall not constitute a waiver of
              such right or provision.
            </TermParagraph>
            <TermParagraph>
              If any provision of these Terms of Service is found by a competent
              authority to be invalid, unlawful or unenforceable to any extent,
              such provision will to that extent be severed from the other
              provisions of the Terms of Service remain in full force and
              effect.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Governing Law</ChildTitle>
            These Terms of Service will be governed by and construed in
            accordance with the laws of England. You and we each agree that the
            English courts will have non-exclusive jurisdiction over any claim
            or dispute arising from, or related to, the ordering and/or supply
            of Partner Services via the BookingCampus Platform.
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Interpretation</ChildTitle>
            Where applicable, words in these Terms of Service and Conditions
            that are defined in the singular shall retain the same definition in
            the plural, and vice versa. Words in the masculine include the
            feminine, and vice versa.
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Trial Subscription</ChildTitle>
            <TermParagraph>
              “Trial Subscription” means the One-{TRIAL_DURATIONS[TRIAL_DURATION_APPLY].name} period during which the
              Service is made available to You, and which starts upon Your
              online registration. Trial Subscription is permitted solely for
              You to use to determine whether to purchase a full subscription to
              the Service. BookingCampus reserve the right to terminate any
              Trial Subscription not used for purposes of determining whether to
              purchase a subscription. A maximum of one (1) Trial Subscription
              is allowed per BookingCampus user. BookingCampus reserve the right
              to cease the Trial Subscription or refuse use of Trial
              Subscription if You have had prior use of a Trial Subscription.
              Multiple uses of Trial Subscription will cause cancellation of all
              active accounts by that user.
            </TermParagraph>
            <TermParagraph>
              BookingCampus reserve the right at any time to terminate the Trial
              Subscription with or without notice. During the Trial Subscription
              You may use the Service in accordance with terms and conditions of
              these Terms.
            </TermParagraph>
            <TermParagraph>
              Cancellation of the Trial Subscription must be communicated to
              BookingCampus 24 hours prior to the end of the Trial Subscription
              by emailing{" "}
              <a
                href="mailto:info@bookingcampus.com"
                className="break-words"
                rel="noreferrer"
              >
                info@bookingcampus.com
              </a>
              . Service will become chargeable after the Trial Subscription if
              no cancellation notice is received 24 hours prior to the end of
              Trial Subscription.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>Fees and Payment for Purchased Service</ChildTitle>
            <TermParagraph>
              You agree to monthly or annual recurring billing, starting
              immediately after Your One-{TRIAL_DURATIONS[TRIAL_DURATION_APPLY].name} Trial Subscription. Billing
              occurs on the same date each month (monthly subscription) and same
              date each year (annual subscription). Fees will be billed in
              advance of use. You agree that You may not be refunded or credited
              for partial months of service (monthly subscription) or partial
              years of service (annual subscription), or for periods in which
              Your account remains open but You do not use the Service. While
              subscribed to BookingCampus You will provide BookingCampus with a
              valid debit or credit card (“card”) and authorise BookingCampus to
              deduct charges against the card. You acknowledge anyone using a
              card represents and warrants that they are authorised to use that
              card, and that any and all charges may be billed to that card and
              will not be rejected. If BookingCampus is unable to process Your
              card order for any reason BookingCampus reserve the right to
              suspend Your account until Your payment can be processed. You
              agree that we may re-attempt payment processing upon receiving
              updated billing details.
            </TermParagraph>
            <TermParagraph>
              Coupon codes are only eligible for first time users. You may not
              apply coupon codes after sign-up. Coupon codes applied to accounts
              found to not be first time users will be removed. BookingCampus
              reserve the right to remove a coupon code at any time and for any
              reason.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>
              Subscription Change and Account Cancellation
            </ChildTitle>
            <TermParagraph>
              You may change Your Service subscription type at any time by
              accessing account details in Settings or by contacting{" "}
              <a
                href="mailto:info@bookingcampus.com"
                className="break-words"
                rel="noreferrer"
              >
                info@bookingcampus.com
              </a>
              . Upgrading to a higher priced subscription authorises
              BookingCampus to charge a prorated amount at the time the upgrade
              is conducted. BookingCampus reserve the right to not offer credits
              if You change to a lower priced subscription. The invoice
              following any subscription change will reflect the new billing
              amount.
            </TermParagraph>
            <TermParagraph>
              You may cancel Your Service subscription at any time from within
              the Settings and Subscription section of your account. You may
              also cancel via email by contacting{" "}
              <a
                href="mailto:info@bookingcampus.com"
                className="break-words"
                rel="noreferrer"
              >
                info@bookingcampus.com
              </a>{" "}
              from the email address associated with Your BookingCampus account.
              Cancellation requests must occur 24 hours before your next billing
              period to not incur an additional subscription charge.
              Cancellations after billing are not entitled to refunds.
            </TermParagraph>
          </TermParagraph>
        </TermSection>
        <TermSection title="Updating the service and these Terms of Service">
          <TermParagraph>
            These Terms of Service were last updated on 01 Octorber 2023.
          </TermParagraph>
          <TermParagraph>
            We may update these Terms of Service from time to time, and will
            post any changes on this page. BookingCampus may modify or replace
            any of these Terms of Service, or change, suspend, or discontinue
            the BookingCampus Services (including without limitation, the
            availability of any feature, database, or content) at any time.
          </TermParagraph>
          <TermParagraph>
            We will notify you by posting a notice on the BookingCampus
            Platform, or by sending you notice through the BookingCampus
            Platform, your BookingCampus Account or via email. If we make any
            substantive changes, we will notify you through email, or website
            pop-ups within our BookingCampus Platform.
          </TermParagraph>
          <TermParagraph>
            Your continued use of the BookingCampus Platform following the
            posting of changes will mean that you accept and agree to the
            changes. As long as you comply with these Terms of Service,
            BookingCampus grants you a personal, non-exclusive,
            non-transferable, limited privilege to enter and use the
            BookingCampus Platform.
          </TermParagraph>
          <TermParagraph>
            BookingCampus may also impose limits on certain features and
            services or restrict your access to parts or all of the
            BookingCampus Services without notice or liability. Such
            modification will not apply to any Bookings/Orders already placed.
          </TermParagraph>
        </TermSection>
        <TermSection title="Need help with anything?">
          <TermParagraph>
            We care about your experience and want to ensure we maintain the
            highest standards possible.
          </TermParagraph>
          <TermParagraph>
            If you have any feedback, any questions concerning the BookingCampus
            Platform, or would like to make a complaint about one of our
            Partners or their Partner Services, please:
            <ul>
              <li>
                speak to the Partner yourself to try and resolve the issue;
              </li>
              <li>
                leave an honest review and/or rating for the Partner on the
                BookingCampus Platform to reflect your experience; or
              </li>
              <li>
                email us at{" "}
                <a
                  href="mailto:info@bookingcampus.com"
                  className="break-words"
                  rel="noreferrer"
                >
                  info@bookingcampus.com
                </a>
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            If you reach out to us at{" "}
            <a
              href="mailto:info@bookingcampus.com"
              className="break-words"
              rel="noreferrer"
            >
              info@bookingcampus.com
            </a>
            , we may contact the Partner to attempt to resolve the issue. If we
            are unable to resolve the issue following contact with the Partner,
            and if we consider it to be fair in all the circumstances, then we
            may, at our sole discretion and always as a last resort, elect to
            give you a refund for the disputed / complained about amount. These
            refunds will be processed through the BookingCampus Payment
            Services.
          </TermParagraph>
          <TermParagraph>
            <strong>
              While we take all complaints about our Partners very seriously and
              will always do what we can to help to resolve them, we are not
              responsible to you for the Partner Services and are under no
              obligation to provide you with a refund or any other recompense if
              you are dissatisfied with any Partner Services.
            </strong>
          </TermParagraph>
        </TermSection>
      </TermContainer>
    </LayoutBase>
  );
};

export default TermOfService;
