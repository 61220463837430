import tw from "twin.macro";
import Header from "booking-campus/components/Header";
import Footer from "booking-campus/components/Footer";

const Container = tw.div`bg-bc-primary2`;

const LayoutBase = ({ children }) => {
  return (
    <Container>
      <Header variant="neutral" />
      {children}
      <Footer />
    </Container>
  );
};

export default LayoutBase;
