import { PhoneNumberUtil } from "google-libphonenumber";

export const isCorrectPhoneNumber = (value) => {
  if (!value) return false;
  const { REACT_APP_DEFAULT_REGION } = process.env;
  const phoneUtil = PhoneNumberUtil.getInstance();
  let number = phoneUtil.parseAndKeepRawInput(
    value,
    `${REACT_APP_DEFAULT_REGION}`
  );
  if (phoneUtil.isValidNumberForRegion(number, REACT_APP_DEFAULT_REGION)) {
    return true;
  }
  number = phoneUtil.parseAndKeepRawInput(value);
  return phoneUtil.isValidNumberForRegion(number, REACT_APP_DEFAULT_REGION);
};
