import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import f01 from "images/bc/icons/f01.svg";
import f02 from "images/bc/icons/f02.svg";
import f03 from "images/bc/icons/f03.svg";
import f04 from "images/bc/icons/f04.svg";
import f05 from "images/bc/icons/f05.svg";
import f06 from "images/bc/icons/f06.svg";
import { ContentXl } from "components/misc/Layouts";

const Container = tw.div`relative pt-14`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-between lg:-mx-8 pt-0 pb-20 md:pb-26`}
`;
const Heading = tw(
  SectionHeading
)`w-full mb-6 text-bc-neutral1 bg-clip-text bg-gradient-to-r from-bc-300 to-bc-500`;
const Description = tw(SectionDescription)`w-full text-center text-bc-gray2`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-5 px-2 pt-8 pb-6`}
  .imageContainer {
    ${tw`border text-center rounded-full p-4 flex-shrink-0`}
    img {
      ${tw`h-9`}
    }
  }

  .textContainer {
    ${tw`sm:ml-5 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold leading-none text-bc-neutral1`}
    ${css`font-size: 1.3rem;`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-base text-secondary-100 leading-loose text-bc-neutral3`}
  }
`;

export default ({
  cards = null,
  heading = "Global Features",
  description = "",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: f01,
      title: "Real-time Availability ",
      description: "Provide customers with real-time information about available appointment slots.",
    },
    {
      imageSrc: f02,
      title: "Multi-location Support",
      description: "Handle bookings and management for all locations from a centralized dashboard.",
    },
    {
      imageSrc: f03,
      title: "Online Payments",
      description: "Enable secure online payment options for customers to book and pay in advance.",
    },
    {
      imageSrc: f04,
      title: "Customisable Services",
      description: "Allow beauty shop owners to create and customise their service offerings.",
    },
    {
      imageSrc: f05,
      title: "Staff Management",
      description: "Implement a feature that enables beauty shop owners to manage their staff schedules efficiently.",
    },
    {
      imageSrc: f06,
      title: "Automated Reminders",
      description: " Send automated reminders to both customers and beauty salons about upcoming appointments.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ContentXl>
        <ThreeColumnContainer>
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
          {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                <span className="imageContainer">
                  <img src={card.imageSrc || ""} alt="" />
                </span>
                <span className="textContainer">
                  <span className="title">{card.title}</span>
                  <p className="description">{card.description}</p>
                </span>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentXl>
    </Container>
  );
};
