import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import w01 from "images/bc/icons/w01.svg";
import w02 from "images/bc/icons/w02.svg";
import w03 from "images/bc/icons/w03.svg";
import w04 from "images/bc/icons/w04.svg";
import { ContentXl } from "components/misc/Layouts";

const Container = tw.div`relative`;

const FourColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-between -mx-4 py-20 md:pt-24 md:pb-22`}
`;
const Heading = tw(SectionHeading)`w-full text-bc-black1`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 py-8`}
  .imageContainer {
    ${tw`w-full text-center rounded-full p-5 mb-2 flex-shrink-0 flex flex-col items-center`}
    img {
      ${tw`w-16 h-16`}
    }
  }

  .textContainer {
    ${tw`p-4 text-center`}
  }

  .title {
    ${tw`tracking-wider font-bold text-bc-neutral1 text-center`}
    ${css`
      font-size: 1.35rem;
    `}
  }

  .description {
    ${tw`mt-4 font-normal leading-relaxed text-base text-bc-neutral3`}
  }
`;

export default ({
  cards = null,
  heading1 = "Why choose",
  heading2 = "BookingCampus",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: w01,
      title: "Enhanced Convenience",
      description: "",
    },
    {
      imageSrc: w02,
      title: "Streamlined Operations",
      description: "",
    },
    {
      imageSrc: w03,
      title: "Customer Retention and Loyalty",
      description: "",
    },
    {
      imageSrc: w04,
      title: "Improved Revenue Generation",
      description: "",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ContentXl>
        <FourColumnContainer>
          <Heading>{heading1}</Heading>
          <Heading tw="leading-none">{heading2}</Heading>
          <VerticalSpacer />
          {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                <div className="imageContainer">
                  <img src={card.imageSrc || ""} alt="" />
                </div>
                <div className="textContainer">
                  <span className="title">{card.title}</span>
                  {card.description && (
                    <p className="description">{card.description}</p>
                  )}
                </div>
              </Card>
            </Column>
          ))}
        </FourColumnContainer>
      </ContentXl>
    </Container>
  );
};
