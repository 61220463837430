import { CardElement } from "@stripe/react-stripe-js";
import { Form } from "antd";

const PaymentMethodField = ({ options, onChange, cardError }) => {
  const { errors } = Form.Item.useStatus();
  return (
    <div className={errors.length > 0 || cardError ? "invalid-card" : ""}>
      <CardElement options={options} onChange={onChange} />
      {cardError && (
        <div class="ant-form-item-explain-error">{cardError}</div>
      )}
    </div>
  );
};

export default PaymentMethodField;
