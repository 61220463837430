import React from "react";
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import TopArea from "booking-campus/components/TopArea";
import Features from "booking-campus/components/Features";
import Pricing from "booking-campus/components/Pricing";
import WhyChoose from "booking-campus/components/WhyChoose";
import HowItWorks from "booking-campus/components/HowItWorks";
import Feedback from "booking-campus/components/Feedback";
import ContactUs from "booking-campus/components/ContactUs";
import Footer from "booking-campus/components/Footer";

export default () => {
  return (
    <>
      <TopArea />
      <Features />
      <div id="pricing">
        <Pricing />
      </div>
      <WhyChoose />
      <HowItWorks />
      <Feedback />
      <ContactUs />
      <Footer />
    </>
  );
};
