import React from "react";
import tw from "twin.macro";

import LogoImage from "images/bc/logo-y.svg";
import { ContentXl } from "components/misc/Layouts";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

const Container = tw.div`relative z-0 bg-white`;
const Content = tw(ContentXl)`pt-16 pb-8 text-bc-neutral1`;
const FiveColumns = tw.div`flex flex-wrap justify-between`;
const Column = tw.div`w-1/2 md:w-1/3 lg:w-1/5 mb-8 md:mb-0 md:pl-16 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full md:w-1/3 lg:w-1/5 flex-grow`;
const ColumnHeading = tw.h5`font-bold uppercase`;
const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const LinkItem = tw(
  Link
)`border-b-2 border-transparent hocus:text-bc-neutral1 hocus:font-semibold pb-1 transition duration-300`;
const HashLinkItem = tw(
  HashLink
)`border-b-2 border-transparent hocus:text-bc-neutral1 hocus:font-semibold pb-1 transition duration-300`;
const LinkA = tw.a`text-bc-primary1 hocus:text-bc-primary1 hocus:text-opacity-70`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`h-16`;
const CompanyAddress = tw.p`mt-4 font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center md:text-left`;
const CopyrightAndCompanyInfoRow = tw.div`pb-10 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`;
const CopyrightNotice = tw.div``;
const CompanyInfo = tw.div``;
const Divider = tw.div`mt-14 mb-8 border-b border-bc-neutral4`;
const SocialList = styled.dl`
  ${tw`flex text-lg my-2 justify-center md:justify-start`}
  dd {
    ${tw`p-2`}
    &:first-child {
      ${tw`pl-0`}
    }
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <Link to="/">
                <LogoImg src={LogoImage} />
              </Link>
            </LogoContainer>
            <CompanyAddress>
              <strong>BOOKINGCAMPUS LIMITED.</strong>
              <br />
              Company Number:{" "}
              <LinkA
                href="https://find-and-update.company-information.service.gov.uk/company/15062307"
                target="_blank"
                rel="noreferrer"
              >
                15062307
              </LinkA>
              .
              <br />
              Registered in England & Wales.
              <br />
              Head Office Address: 164 Long Lane, London
              <br />
              N3 2HX, United Kingdom
            </CompanyAddress>
            <SocialList>
              <dd>
                <LinkA
                  href="https://www.facebook.com/bookingcampus.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bc-facebook"></i>
                </LinkA>
              </dd>
              <dd>
                <LinkA href="https://instagram.com/bookingcampus.uk" target="_blank" rel="noopener noreferrer">
                  <i className="bc-instagram"></i>
                </LinkA>
              </dd>
              <dd>
                <LinkA
                  href="https://www.tiktok.com/@bookingcampus"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bc-tiktok"></i>
                </LinkA>
              </dd>
              {/* <dd>
                <LinkA href="#" target="_blank" rel="noopener noreferrer">
                  <i className="bc-youtube"></i>
                </LinkA>
              </dd> */}
            </SocialList>
          </CompanyColumn>
          <Column>
            <ColumnHeading>BookingCampus</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <HashLinkItem to="/#pricing">Pricing</HashLinkItem>
              </LinkListItem>
              <LinkListItem>
                <LinkItem to="/contact-us">Contact us</LinkItem>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <LinkItem to="/terms-of-use">Terms of Use</LinkItem>
              </LinkListItem>
              <LinkListItem>
                <LinkItem to="/terms-of-service">Terms of Service</LinkItem>
              </LinkListItem>
              <LinkListItem>
                <LinkItem to="/privacy-policy">Privacy Policy</LinkItem>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>
            &copy; Copyright 2023-2024, BookingCampus.
          </CopyrightNotice>
          <CompanyInfo>BookingCampus - A UK Online Booking Platform</CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
