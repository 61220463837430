import styled from "styled-components";
import tw from "twin.macro";
export const PrimaryButton = styled.button`
  ${tw`
    text-base
    inline-block
    px-9 
    py-3 
    font-bold 
    rounded-lg 
    text-center 
    border-2 
    border-bc-primary1 
    border-opacity-0 
    bg-bc-primary1 
    text-white 
    hocus:bg-opacity-90 
    hocus:text-white 
    hocus:outline-none 
    transition 
    duration-300
  `}
  &:disabled {
    ${tw`bg-opacity-70`}
  }
`;
export const SecondaryButton = tw(PrimaryButton)`
    border-bc-secondary1
    bg-bc-secondary1 
`;
export const OutlinePrimaryButton = styled.button`
  ${tw`
    text-base
    inline-block
    px-9 
    py-3 
    font-bold 
    rounded-lg 
    text-center 
    border-2 
    border-bc-primary1 
    text-bc-primary1 
    hocus:bg-bc-primary1
    hocus:bg-opacity-10 
    hocus:text-bc-primary1
    hocus:outline-none 
    transition 
    duration-300
  `}
  &:disabled {
    ${tw`bg-opacity-70`}
  }
`;
export const OutlineSecondaryButton = tw(OutlinePrimaryButton)`
    border-bc-secondary1
    text-bc-secondary1 
    hocus:bg-bc-secondary1
    hocus:bg-opacity-10
    hocus:text-bc-secondary1
`;
