import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Content4Xl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { OutlineSecondaryButton } from "components/misc/Buttons.js";
import topImageSrc from "images/bc/image_banner.png";
import topBackgroundSrc from "images/bc/bg-top.svg";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Header from "./Header";

const BackgroundContainer = styled.div`
  ${(props) => css`
    background-image: url("${props.background}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    position: relative;
    overflow: hidden;
  `}
  ${tw`py-8 h-[calc(100vh - 3rem)]`}
`;
const ContainerImage = tw.img`block xl:hidden`;
const Content = styled(Content4Xl)`
  ${tw`relative z-[2] h-full xl:max-w-[89%] flex flex-col xl:flex-row`}
  ${(props) => css`
    &::before {
      ${tw`hidden absolute inset-0 z-0 xl:block`}
      content: "";
      background-image: url("${props.image}");
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: right;
      background-size: calc(100% - 29rem);
      transform: translateX(4%);
    }
  `}
`;
const Row = tw.div`flex items-center flex-col xl:flex-row relative w-full`;
const TextColumn = tw.div`text-center xl:text-left max-w-lg xl:pr-8 `;
const Heading = tw(
  SectionHeading
)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight text-white`;
const Description = tw(
  SectionDescription
)`mt-8 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0 text-bc-gray1`;
const StartButton = tw(OutlineSecondaryButton)`my-4 xl:my-12`;

export default ({
  heading1 = "Instant Booking",
  heading2 = "A reliable software",
  description = "Run your business like a pro with easy-to-use booking software with 24/7 automated online booking, reminders, payments, and more",
  primaryButtonText = "Get Started",
  primaryButtonLink = "/contact-us",
  imageSrc = topImageSrc,
}) => {
  const history = useHistory();

  const handleStart = () => {
    history.push(primaryButtonLink);
  };

  return (
    <BackgroundContainer background={topBackgroundSrc}>
      <Header />
      <Content image={imageSrc}>
        <Row>
          <TextColumn>
            {heading1 && <Heading>{heading1}</Heading>}
            {heading2 && <Heading>{heading2}</Heading>}
            {description && <Description>{description}</Description>}
            <StartButton onClick={handleStart}>{primaryButtonText}</StartButton>
          </TextColumn>
        </Row>
        <Row>
          <ContainerImage src={imageSrc} alt="" />
        </Row>
      </Content>
    </BackgroundContainer>
  );
};
