import LayoutBase from "booking-campus/components/LayoutBase";
import TermSection, {
  TermContainer,
  TermTitle,
  TermParagraph,
} from "booking-campus/components/TermSection";

const TermOfUse = () => {
  return (
    <LayoutBase>
      <TermContainer>
        <TermTitle>Terms of use</TermTitle>

        <TermSection title="Introduction">
          <TermParagraph>
            These are the terms and conditions of use for{" "}
            <a
              href="bookingCampus.com"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              www.BookingCampus.com
            </a>{" "}
            (Terms) and all affiliated websites owned and operated by
            BookingCampus.com SV Ltd. (we, us and our), including all
            subdomains, subdirectories, mobile sites, and mobile applications
            (collectively, the Site). We are a limited company, registered in
            England. Our registered company number is 15062307, and our
            registered office is at 164 Long Lane, London, England, N3 2HX,
            United Kingdom.
          </TermParagraph>
          <TermParagraph>
            Your use of the Site will be subject to these Terms and by using the
            Site you agree to be bound by them.
          </TermParagraph>
          <TermParagraph>
            These terms and conditions of use only apply to your use of our
            Site. These terms and conditions of use DO NOT apply to the third
            party goods and services which are available for booking and
            purchases on our Site. The online ordering and booking-related
            services (including payments, vouchers and bookings) we make
            available through the Site (the BookingCampus Services) are provided
            subject to our{" "}
            <a
              href="/terms-of-service"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              Terms of Service
            </a>
            . Our third party partners fulfil these services (including the
            provision of your selected treatments) (the Partner Services).
          </TermParagraph>
          <TermParagraph>
            We may change these Terms from time to time by changing them on the
            Site. Your continued use of the Site following the posting of
            changes will mean that you accept and agree to the changes. As long
            as you comply with these Terms of Use, BookingCampus grants you a
            personal, non-exclusive, non-transferable, limited privilege to
            enter and use the Site. These Terms were last updated on 01 10 2023.
          </TermParagraph>
        </TermSection>
        <TermSection title="Feedback and Information">
          <TermParagraph>
            Please submit any feedback you have about these Terms or any
            questions concerning the Site by email to{" "}
            <a
              href="mailto:info@bookingcampus.com"
              className="break-words"
              rel="noreferrer"
            >
              info@BookingCampus.com
            </a>
            .
          </TermParagraph>
        </TermSection>
        <TermSection title="Privacy">
          <TermParagraph>
            If you use our Site, BookingCampus’s{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            applies and forms a part of these Terms of Use. Unfortunately, the
            transmission of information via the internet is not completely
            secure. We do our best to protect your personal information, but we
            cannot guarantee the security of your data transmitted to us – even
            if there is a special notice that a particular transmission (for
            example, credit card information) is encrypted. Any transmission is
            at your own risk.
          </TermParagraph>
        </TermSection>
        <TermSection title="Access to the site">
          <TermParagraph>
            It is your responsibility to ensure your equipment (computer,
            laptop, netbook, tablet or other mobile device) meets all the
            necessary technical specifications to enable you to access and use
            the Site and is compatible with the Site.
          </TermParagraph>
          <TermParagraph>
            You must ensure that any registration details you provide are
            accurate. If you choose, or you are provided with, a log-on ID (such
            as a username and password or other identifier) as part of our
            security procedures, you must treat such information as confidential
            and must not reveal it to anyone else. You are responsible for all
            activities that occur under your log-on ID and must notify us
            immediately of any unauthorised use or other security breach of
            which you become aware. We reserve the right to disable any log-on
            ID, at any time, if in our opinion you have failed to comply with
            any of the provisions of these Terms or if any details you provide
            for the purposes of registering as a user prove to be false.
          </TermParagraph>
        </TermSection>
        <TermSection title="Age requirements">
          <TermParagraph>
            You must be at least the age of majority or older in your
            jurisdiction of residence in order to use the BookingCampus
            Platform. If you live in a country or area that limit the use of the
            BookingCampus Platform because of your age, or restricts the ability
            to enter into contracts such as this one due to age, you must abide
            by such age limits and you must not use the BookingCampus Platform
            or BookingCampus Services, or only do so with your parent or legal
            guardian’s permission. Please have your parent or legal guardian
            read these terms with you. If you are a parent or legal guardian,
            and you allow your child or dependant to use these BookingCampus
            Services, then these terms apply to you and you are responsible for
            your child’s or dependant's activity on the services.
          </TermParagraph>
        </TermSection>
        <TermSection title="Accounts, passwords and security">
          <TermParagraph>
            You do not need an account to access the Site, but you may need an
            account to use certain functionality on our Site.
          </TermParagraph>
          <TermParagraph>
            Each individual may only have one account on our Site (whether this
            is your own account, or part of a shared account type, like a family
            account). Your account is non-transferrable and may not be sold,
            accessed by, combined or otherwise shared with anyone else.
          </TermParagraph>
          <TermParagraph>
            We may immediately terminate your account if we determine that you
            have breached any part of these Terms, including:
            <ul>
              <li>allowing any third party to access or use your account;</li>
              <li>
                committing fraud or falsifying information in connection with
                your use of our Site or in connection with your account on our
                Site;
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            If we or you terminate your account, then we may, without
            limitation:
            <ul>
              <li>
                cancel any pending, current or future promotional account
                credits and any unredeemed vouchers in your account.
              </li>
              <li>
                disallow or prevent you from joining under a new account unless
                we formally invite you to do so.
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            We reserve the right to hold you liable for any and all damages
            caused by your conduct, to pursue legal action through relevant
            local and national law enforcement authorities and to notify your
            internet service provider of any fraudulent activity we associate
            with you or your use of our Site.
          </TermParagraph>
        </TermSection>
        <TermSection title="What you are allowed to do">
          <TermParagraph>
            Unless you are a Partner (a selected third-party provider of goods
            and services, who offers their goods and services for sale on the
            Site), you may only use the Site for non-commercial use and only in
            accordance with these Terms. You may retrieve and display content
            from the Site on a computer screen, print and copy individual pages
            and, subject to the next section, store such pages in electronic
            form. Additional terms may also apply to certain features, parts or
            content of the Site and, where they apply, will be displayed
            on-screen or accessible via a link.
          </TermParagraph>
        </TermSection>
        <TermSection title="Your promises to us">
          <TermParagraph>
            <strong>Use only our authorised App, on the right device</strong>:
            You may only access the BookingCampus Services using authorised
            means. It is your responsibility to check to ensure you download the
            correct App for your device. BookingCampus is not liable if you do
            not have a compatible mobile device or if you download the wrong
            version of the App for your mobile device. BookingCampus reserves
            the right to terminate the BookingCampus Services and the use of the
            Site if you are using the Site with an incompatible or unauthorised
            device.
          </TermParagraph>
          <TermParagraph>
            <strong>
              Comply with the law and these terms, and use the Site
              appropriately
            </strong>
            : You must only use the Site and anything available from the Site
            for lawful purposes (complying with all applicable laws and
            regulations), in a responsible manner, and not in a way that might
            damage our name or reputation or that of any of our affiliates.
          </TermParagraph>
          <TermParagraph>
            By using the Site, you agree that:
            <ul>
              <li>
                You will only use the Site for your sole, personal use (unless
                you are a Partner) and will not resell it to a third party;
              </li>
              <li>
                You will not authorise others to use your account (except as
                permitted as part of a family account);
              </li>
              <li>
                You will not assign or otherwise transfer your account to any
                other person or legal entity;
              </li>
              <li>
                You will not use an account that is subject to any rights of a
                person other than you without appropriate authorisation;
              </li>
              <li>
                You will not use the Site for unlawful purposes, including but
                not limited to sending or storing any unlawful material or for
                fraudulent purposes;
              </li>
              <li>
                You will not use the Site to cause nuisance, annoyance or
                inconvenience;
              </li>
              <li>You will not impair the proper operation of the network;</li>
              <li>You will not try to harm the Site in any way whatsoever;</li>
              <li>
                You will not copy, or distribute the App or other BookingCampus
                content without written permission from BookingCampus;
              </li>
              <li>
                You will keep your account password (or any identification we
                provide you which allows access to the Site) secure and
                confidential;
              </li>
              <li>
                You will provide us with whatever proof of identity we may
                reasonably request;
              </li>
              <li>
                You will only use our Site through an internet connection you
                are authorized to use;
              </li>
              <li>
                You are aware that when BookingCampus Services may require
                receiving or sending messages by SMS (if available in your
                jurisdiction), standard messaging charges (if any) will apply;
              </li>
              <li>
                You will not use the Site with an incompatible or unauthorized
                device;
              </li>
              <li>
                You will comply with all applicable laws including, secondary
                legislation of the area in which you are present while using the
                Site.
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            <strong>
              BookingCampus reserves the right to immediately terminate the
              BookingCampus Services and the use of the Site if you do not
              comply with any of the above rules.
            </strong>
          </TermParagraph>
        </TermSection>
        <TermSection title="What you are not allowed to do">
          <TermParagraph>
            Except to the extent expressly set out in these Terms, you are not
            allowed to:
            <ul>
              <li>
                'scrape' content or store content of the Site on a server or
                other storage device connected to a network or create an
                electronic database by systematically downloading and storing
                all of the content of the Site;
              </li>
              <li>
                remove or change any content of the Site or attempt to
                circumvent security or interfere with the proper working of the
                Site or the servers on which it is hosted; or
              </li>
              <li>
                create links to the Site from any other website or "frame" or
                "mirror" the Site on any other server or wireless or
                Internet-based device (this does not include Partners
                implementing the BookingCampus Widget, as defined in our Partner
                Terms), without our prior written consent, although you may link
                from a website that is operated by you, provided the link is not
                misleading or deceptive and fairly indicates its destination;
                you do not imply that we endorse you, your website, or any
                products or services you offer; you link to (and do not frame or
                replicate) the home page of the Site; and the linked website
                does not contain any content that is unlawful, threatening,
                abusive, defamatory, pornographic, obscene, vulgar, indecent,
                offensive or which infringes on the intellectual property rights
                or other rights of any third party.
              </li>
              <li>
                reverse engineer or access the Site in order to:
                <ul>
                  <li>design or build a competitive product or service,</li>
                  <li>
                    design or build a product using similar ideas, features,
                    functions or graphics of the Site, or
                  </li>
                  <li>
                    copy any ideas, features, functions or graphics of the Site;
                  </li>
                </ul>
              </li>
              <li>
                launch an automated program or script, including, but not
                limited to, web spiders, web crawlers, web robots, web ants, web
                indexers, bots, viruses or worms, or any program which may make
                multiple server requests per second, or unduly burdens or
                hinders the operation and/or performance of the Site;
              </li>
              <li>
                license, sublicense, sell, resell, transfer, assign, distribute
                or otherwise commercially exploit or make available to any third
                party the Site in any way;
              </li>
              <li>modify or make derivative works based upon the Site;</li>
              <li>
                send spam or otherwise duplicative or unsolicited messages of
                any kind in violation of applicable laws;
              </li>
              <li>
                send or store infringing, obscene, threatening, libellous, or
                otherwise unlawful or tortious material, including material
                harmful to children or anything which would violate third party
                privacy rights;
              </li>
              <li>
                send or store material containing software viruses, worms,
                Trojan horses or other harmful computer code, files, scripts,
                agents or programs;
              </li>
              <li>
                interfere with or disrupt the integrity or performance of the
                Site, the Site or the data contained therein; or
              </li>
              <li>
                attempt to gain unauthorized access to the Site, the Site or its
                related systems or networks.
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            BookingCampus will have the right to investigate and prosecute
            violations of any of the above to the fullest extent of the law.
            BookingCampus may involve and cooperate with law enforcement
            authorities in prosecuting users who violate these Terms. You
            acknowledge that BookingCampus has no obligation to monitor your
            access to or use of the Site or to review or edit any content on the
            Site, but has the right to do so for the purpose of operating the
            Site, to ensure your compliance with these Terms (or any other terms
            between you and BookingCampus), or to comply with applicable law or
            the order or requirement of a court, administrative agency or other
            governmental body. BookingCampus reserves the right, at any time and
            without prior notice, to remove or disable access to any content
            that BookingCampus, at its sole discretion, considers to be in
            violation of these Terms of Service or otherwise harmful to the
            BookingCampus Services.
          </TermParagraph>
          <TermParagraph>
            If you breach any part of these Terms, all your rights under these
            Terms will terminate immediately.
          </TermParagraph>
        </TermSection>
        <TermSection title="User content">
          <TermParagraph>
            The Site may, from time to time, allow you to upload user-generated
            content and may also allow you to communicate that content, either
            to selected recipients or in public areas, such as review sections
            (collectively <strong>User Content Areas</strong>).
          </TermParagraph>
          <TermParagraph>
            We do not control the material submitted to User Content Areas
            (collectively <strong>User Submissions</strong>), nor are User
            Content Areas actively moderated. You are solely responsible for the
            content of your User Submissions as submitted by you and acknowledge
            that all User Submissions express the views of their respective
            authors, and not our views.
          </TermParagraph>
          <TermParagraph>
            If you participate in any User Content Areas, you must keep all User
            Submissions relevant to the purpose of the User Content Area and the
            nature of any topic.
          </TermParagraph>
          <TermParagraph>
            User Submissions must not:{" "}
            <ul>
              <li>
                contain, transmit, distribute, link to or otherwise make
                available, or advertise or promote any content that infringes
                any intellectual property rights or data protection, privacy or
                other rights of any other person, is defamatory or in breach of
                any contractual duty or any obligation of confidence, is
                obscene, sexually explicit, threatening, abusive, harassing,
                inciteful of violence or hatred, blasphemous, discriminatory (on
                any ground), liable to cause anxiety, alarm or embarrassment,
                knowingly false or misleading, or that does not comply with all
                applicable laws and regulations or is otherwise objectionable ("
                <strong>Prohibited Content</strong>");
              </li>
              <li>
                impersonate any person or entity or otherwise misrepresent your
                relationship with any person or entity;
              </li>
              <li>infringe any third party's intellectual property rights;</li>
              <li>
                contain, transmit or distribute any unsolicited or unauthorised
                advertising, marketing or promotional material (including paid
                or otherwise sponsored reviews if the payment or sponsorship is
                undisclosed in the material) or other form of solicitation
                (spam); or
              </li>
              <li>
                transmit or distribute any virus and/or other code that has
                contaminating or destructive elements.
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            You agree that by submitting content, feedback or other information
            to BookingCampus, including any User Submission, you grant us and
            our affiliates a perpetual, irrevocable, worldwide, non-exclusive,
            royalty-free and fully sub-licensable right and licence to use,
            reproduce, modify, adapt, publish, translate, create derivative
            works from, distribute, perform and display such User Submission (in
            whole or part) and/or to incorporate it in other works in any form,
            media or technology, and you waive any moral rights you may have in,
            or to be identified as the author, of such User Submission.
          </TermParagraph>
          <TermParagraph>
            Whilst we do not pre-screen User Submissions, we reserve the right,
            in our sole discretion, to delete, edit or modify any User
            Submission submitted by you and/or to close any topic, at any time
            without notice to you.
          </TermParagraph>
          <TermParagraph>
            Complaints about the content of any User Submission must be sent to{" "}
            <a
              href="mailto:info@bookingcampus.com"
              className="break-words"
              rel="noreferrer"
            >
              info@BookingCampus.com
            </a>{" "}
            and must contain details of the specific User Submission giving rise
            to the complaint.
          </TermParagraph>
        </TermSection>
        <TermSection title="Intellectual property">
          <TermParagraph>
            BookingCampus alone (and its third party licensors, where
            applicable) shall own all right, title and interest, including all
            related intellectual property rights, in and to the Site (including
            text, graphics, software, photographs and other images, videos,
            sound, trade marks and logos), and any suggestions, ideas,
            enhancement requests, feedback, recommendations or other information
            provided by you or any other party relating to the BookingCampus
            Services and Site.
          </TermParagraph>
          <TermParagraph>
            These Terms, and our{" "}
            <a
              href="/terms-of-service"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              Terms of Service
            </a>
            , do not constitute a sale and do not convey to you any rights of
            ownership in or related to the BookingCampus Services or the Site,
            or any intellectual property rights owned by BookingCampus.
            BookingCampus's name, logo, and the product names associated with
            the BookingCampus Services are trademarks of BookingCampus, its
            affiliated companies or third parties, and no right or license is
            granted to use them.
          </TermParagraph>
          <TermParagraph>
            Except as expressly set out here, nothing in these Terms gives you
            any rights in respect of any intellectual property owned by us or
            our licensors and you acknowledge that you do not acquire any
            ownership rights by downloading content from the Site.
          </TermParagraph>
          <TermParagraph>
            In the event you print off, copy or store pages from the Site (only
            as permitted by these Terms), you must ensure that any copyright,
            trade mark or other intellectual property right notices contained in
            the original content are reproduced.
          </TermParagraph>
          <TermParagraph>
            You may not submit to BookingCampus or to our Site any material
            protected by copyright, trademark or other proprietary right without
            the express permission of the owner of that right, and you will be
            solely responsible for any loss or damages resulting from any
            infringement.
          </TermParagraph>
        </TermSection>
        <TermSection title="Content">
          <TermParagraph>
            We may change the format and content of the Site from time to time.
            You agree that your use of the Site is on an 'as is' and 'as
            available' basis and at your sole risk.
          </TermParagraph>
          <TermParagraph>
            Whilst we try to make sure that all information contained on the
            Site (other than any user-generated content) is correct, it is not
            intended to amount to authority or advice on which reliance should
            be placed. You should check with us or the relevant information
            source before acting on any such information.
          </TermParagraph>
        </TermSection>
        <TermSection title="External links">
          <TermParagraph>
            The Site may, from time to time, include links to external sites,
            which may include links to third party offers and promotions. We
            include these to provide you with access to information, products or
            services that you may find useful or interesting. We are not
            responsible for the content of these sites or for anything provided
            by them and do not guarantee that they will be continuously
            available. The fact that we include links to such external sites
            does not imply any endorsement of or association with their
            operators or promoters.
          </TermParagraph>
        </TermSection>
        <TermSection title="Disclaimers">
          <TermParagraph>
            Except to the extent provided in our{" "}
            <a
              href="/terms-of-service"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              Terms of Service
            </a>
            , we make or give no representation or warranty as to the accuracy,
            completeness, currency, correctness, reliability, integrity,
            quality, fitness for purpose or originality of any content of the
            Site and, to the fullest extent permitted by law, all implied
            warranties, conditions or other terms of any kind are hereby
            excluded and we accept no liability for any loss or damage of any
            kind incurred as a result of you or anyone else using the Site or
            relying on any of its content.
          </TermParagraph>
          <TermParagraph>
            While we try to keep the Site safe and secure, we cannot and do not
            guarantee that any content of the Site will be free from viruses
            and/or other code that may have contaminating or destructive
            elements. It is your responsibility to implement appropriate IT
            security safeguards (including anti-virus and other security checks)
            to satisfy your particular requirements as to the safety and
            reliability of content.
          </TermParagraph>
          <TermParagraph>
            We may, from time to time and without notice, restrict access to
            certain features, parts or content of the Site, or the entire Site,
            to users who have registered with us.
          </TermParagraph>
          <TermParagraph>
            We cannot guarantee the continuous, uninterrupted or error-free
            operability of the Site. There may be times when certain features,
            parts or content of the Site, or the entire Site, become unavailable
            (whether on a scheduled or unscheduled basis) or are modified,
            suspended or withdrawn by us, in our sole discretion, without notice
            to you. You agree that we will not be liable to you or to any third
            party for any unavailability, modification, suspension or withdrawal
            of the Site, or any features, parts or content of the Site.
          </TermParagraph>
        </TermSection>
        <TermSection title="Governing law, dispute resolution">
          <TermParagraph>
            These Terms, and any dispute between you and us regarding these
            Terms or the Site, shall be governed and dealt with by English law.
            Except where excluded by applicable law (including where such
            restriction would be unfair), any claim under these Terms of Use
            must be brought within one (1) year after the cause of action
            arises, or such claim or cause of action is barred. Claims made
            under the separate Terms of Service for goods and services are not
            subject to this limitation. No recovery may be sought or received
            for damages other than out-of-pocket expenses, except that the
            prevailing party will be entitled to costs and attorneys’ fees. In
            the event of any controversy or dispute between BookingCampus and
            you arising out of or in connection with your use of the Site, the
            parties shall attempt, promptly and in good faith, to resolve any
            such dispute. If we are unable to resolve any such dispute within a
            reasonable time (not to exceed thirty (30) days), then either party
            may submit such controversy or dispute to mediation. If the dispute
            cannot be resolved through mediation, then the parties shall be free
            to pursue any right or remedy available to them under applicable
            law.
          </TermParagraph>
        </TermSection>
        <TermSection title="Geographic restrictions">
          <TermParagraph>
            BookingCampus is based in England, where it administers and operates
            the{" "}
            <a
              href="bookingCampus.com"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              www.BookingCampus.com
            </a>{" "}
            site. Other BookingCampus sites and applications may be administered
            and operated from other locations around the world. Although the
            Site is accessible worldwide, not all features, products or services
            discussed, referenced, provided or offered through or on the Site
            are available to all persons or in all geographic locations, or
            appropriate or available for use in other countries. BookingCampus
            may, in its sole discretion, limit the use of any feature, product
            or service to any person or geographic area. Any offer for any
            feature, product or service made on the Site is void where
            prohibited. If you choose to access the Site from outside England,
            you acknowledge you have chosen to do so independently and you are
            solely responsible for complying with applicable local laws.
          </TermParagraph>
        </TermSection>
      </TermContainer>
    </LayoutBase>
  );
};

export default TermOfUse;
