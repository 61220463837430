import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import h01 from "images/bc/icons/h01.svg";
import h02 from "images/bc/icons/h02.svg";
import h03 from "images/bc/icons/h03.svg";
import svgDecoratorBlob from "images//bc/bg-how.svg";
import { ContentXl } from "components/misc/Layouts";

const Container = styled.div`
  ${(props) => css`
    background-image: url("${props.background}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  `}
  ${tw`relative`}
`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-between py-20 md:px-6 md:pt-24 md:pb-32`}
`;
const Heading = tw(
  SectionHeading
)`w-full text-white bg-clip-text bg-gradient-to-r from-bc-300 via-bc-400 to-bc-500`;

const Column = styled.div`
  ${tw`md:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`w-full text-center rounded-full p-5 flex-shrink-0 flex flex-col items-center`}
    img {
      ${tw`h-16`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center w-full`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none text-bc-secondary1 text-center hocus:text-opacity-75`}
  }

  .description {
    ${tw`mt-6 font-normal leading-relaxed text-base text-white text-opacity-70`}
  }
`;

export default ({ cards = null, heading = "How it works" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: h01,
      title: "Register an account",
      description: "",
    },
    {
      imageSrc: h02,
      title: "Setup your business",
      description: "",
    },
    {
      imageSrc: h03,
      title: "Go Live",
      description: "",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container background={svgDecoratorBlob}>
      <ContentXl>
        <ThreeColumnContainer>
          <Heading>{heading}</Heading>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                <div className="imageContainer">
                  <img src={card.imageSrc || ""} alt="" />
                </div>
                <div className="textContainer">
                  <span className="title">{card.title}</span>
                  {card.description && (
                    <p className="description">{card.description}</p>
                  )}
                </div>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentXl>
    </Container>
  );
};
