import React, { useState } from "react";
import tw from "twin.macro";
import InputForm from "booking-campus/components/TwoColWithForm";
import { sendMail } from "booking-campus/services/mail.service";
import { useHistory } from "react-router-dom";
import LayoutBase from "booking-campus/components/LayoutBase";
import { Content3Xl } from "components/misc/Layouts";

export default () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const formCss = tw`md:mb-12`;
  const fields = [
    {
      type: "text",
      name: "name",
      placeholder: "Your Full Name",
      required: true,
    },
    {
      type: "phone",
      name: "phone",
      placeholder: "Your Mobile Phone",
      required: true,
    },
    {
      type: "email",
      name: "email",
      placeholder: "Your Email",
      required: true,
    },
    {
      type: "textarea",
      name: "notes",
      placeholder: "Notes",
    },
  ];

  const handleSubmit = (value) => {
    setIsLoading(true);
    sendMail(value)
      .then(() => {
        history.push("/");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LayoutBase>
      <Content3Xl>
        <InputForm
          fields={fields}
          onSubmit={handleSubmit}
          formCss={formCss}
          description=""
          isLoading={isLoading}
        />
      </Content3Xl>
    </LayoutBase>
  );
};
