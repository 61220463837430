import LayoutBase from "booking-campus/components/LayoutBase";
import TermSection, {
  TermContainer,
  TermTitle,
  TermParagraph,
  ChildTitle,
  GrandChildTitle,
} from "booking-campus/components/TermSection";

const PrivacyPolicy = () => {
  return (
    <LayoutBase>
      <TermContainer>
        <TermTitle>Privacy Policy</TermTitle>
        <TermSection title="Introduction">
          <TermParagraph>
            At BookingCampus.com SV Limited (referred to as "
            <strong>BookingCampus</strong>", "<strong>we</strong>", "
            <strong>us</strong>" and "<strong>our</strong>"), we are committed
            to protecting the privacy of our users. If you are in the UK or the
            EU, we are the data controller, unless otherwise stated. Please read
            the following privacy policy that explains how we collect, manage
            and protect your personal information.
          </TermParagraph>
          <TermParagraph>
            This is the Privacy (and Cookies) Notice ("<strong>Notice</strong>")
            for the website{" "}
            <a
              href="www.BookingCampus.com"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              www.BookingCampus.com
            </a>
            , and all BookingCampus affiliate sites ("<strong>Sites</strong>"),
            or our applications ("
            <strong>Apps</strong>") (together "<strong>Services</strong>").
          </TermParagraph>
        </TermSection>
        <TermSection title="Contacting us">
          <TermParagraph>
            If you have any questions, comments and requests regarding this
            Notice you can get in touch with us via our{" "}
            <a
              href="/contact-us"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              Contact Us
            </a>{" "}
            page, or via email at{" "}
            <a
              href="mailto:support@BookingCampus.com"
              className="break-words"
              rel="noreferrer"
            >
              support@BookingCampus.com
            </a>
            .
          </TermParagraph>
        </TermSection>
        <TermSection title="What information do we collect & how do we use it?">
          <TermParagraph>
            This Privacy Notice will apply to you if:
          </TermParagraph>
          <TermParagraph>
            <ul>
              <li>
                you <strong>visit and browse our Sites and Apps</strong>.
              </li>
              <li>
                You <strong>order products</strong> from or{" "}
                <strong>book appointments</strong> with our Partners through us
                (we'll refer to you as a "<strong>Client</strong>").
              </li>
              <li>
                you are{" "}
                <strong>self-employed and/or you work for a business</strong> on
                the BookingCampus platform (we'll refer to you and/or the
                business as a "<strong>Partner</strong>").
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            We will only use the information we collect about you if there is a
            reason for doing so, and if that reason is permitted under data
            protection law.
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>If you visit our services</ChildTitle>
            We use your information to provide you with our Services. If you
            visit any of our Services, whether you're just browsing or you have
            an account, we will{" "}
            <strong>automatically collect information</strong> from you each
            time you use our Sites. This includes{" "}
            <strong>technical information, information about your visit</strong>{" "}
            and (if you opt-in) <strong>location data</strong>:
          </TermParagraph>
          <TermParagraph>
            <GrandChildTitle>Technical information</GrandChildTitle>
            Technical information may include the phone number, Internet
            Protocol (IP) address, login information, browser type and version,
            browser plug-in types and versions, device IDs, social log-in
            ID/email address, time zone setting, operating system and platform,
            hardware version, device settings (e.g. language and time zone),
            file & software names and types (associated with your device and/or
            the Services), battery & signal strength, information relating to
            your mobile operator or Internet Service Provider (ISP).
          </TermParagraph>
          <TermParagraph>
            <GrandChildTitle>Information about your visit</GrandChildTitle>
            Information about your visit may include the full Uniform Resource
            Locators (URL), clickstream to, through and from our Site (including
            date and time), pages and services you viewed or searched for,
            demographic information (including age and gender), page response
            times, download errors, length of visits to certain pages, page
            interaction information (such as scrolling, clicks, and
            mouse-overs), traceable campaign links (e.g. in emails, or via
            tracking URLs), methods used to browse away from the page, and any
            phone number used to call our customer service number or social
            media handle used to connect with our customer service team and our
            social media accounts.
          </TermParagraph>
          <TermParagraph>
            <GrandChildTitle>Location data</GrandChildTitle>
            Location data includes country location (based on your full or
            partial IP address and/or Google Analytics information) which we use
            to provide location services (if you ask or permit us to), so that
            we can deliver content, advertising or other services that are
            dependent on knowing where you are, like checking for fraudulent
            transactions. Location data may be collected in combination with
            device ID, so we can recognise your mobile browser or device when
            you return to the Service.
          </TermParagraph>
          <TermParagraph>
            Delivery of location services will involve us checking any of the
            following:
            <ul>
              <li>the coordinates (latitude/longitude) of your location,</li>
              <li>
                your current country or region, by referencing your current IP
                address against public sources, and/or
              </li>
              <li>
                your Identifier for Advertisers (IFA) code for your Apple
                device, or the Android ID for your Android device, or a similar
                device identifier.
              </li>
            </ul>
            You can opt-in and out of location sharing by changing your device
            settings
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>
              We use this automatically collected information to{" "}
            </ChildTitle>
            <ul>
              <li>
                understand how individuals use our Site, and how we can improve
                it;
              </li>
              <li>
                ensure content from our site is presented in the most effective
                manner for you and for your devices; and
              </li>
              <li>
                provide you with the information, products and services that you
                request from us or we think you may be interested in.
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>If you contact or engage with us</ChildTitle>
            <TermParagraph>
              If you <strong>contact</strong> or engage with us, we will collect
              your contact information, and the other{" "}
              <strong>communications</strong> information you provide.
            </TermParagraph>
            <TermParagraph>
              Contact information includes basic contact information you
              provide:
              <ul>
                <li>email address,</li>
              </ul>
              <i>and anything else you include, for example:</i>
              <ul>
                <li>first and last name,</li>
                <li>phone number(s),</li>
                <li>
                  social media handle (for example, if you engage with us on
                  social media).
                </li>
              </ul>
            </TermParagraph>
            <TermParagraph>
              <strong>Communications</strong> information includes your
              correspondence with us, for example if you get in touch with our
              Customer Service team about an order or booking or to report a
              problem with our Site. This includes:
              <ul>
                <li>emails,</li>
                <li>texts, in-app messaging & other digital messaging,</li>
                <li>calls,</li>
                <li>letters,</li>
                <li>any in-person conversations you have with us.</li>
              </ul>
            </TermParagraph>
            <TermParagraph>
              <strong>
                We use this contact and communications information to
              </strong>
            </TermParagraph>
            <TermParagraph>
              <ul>
                <li>
                  Contact you if you have asked us to do, including to respond
                  to your queries, troubleshoot problems, and help with any
                  issues you may have with our Services.
                </li>
                <li>
                  Provide you with information you might request about our
                  Services.
                </li>
                <li>
                  Put you in touch with Partners you ask us to share your
                  information with.
                </li>
                <li>
                  Provide you with technical and other service updates (for
                  example, if we update our{" "}
                  <a
                    href="/terms-of-use"
                    target="_blank"
                    className="break-words"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    href="/terms-of-service"
                    target="_blank"
                    className="break-words"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                  ).
                </li>
              </ul>
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>
              If you use our services to book an appointment
            </ChildTitle>
            <TermParagraph>
              As you <strong>use your account</strong>, we may collect
              information about your:
              <ul>
                <li>
                  purchase history, including product orders or appointment
                  bookings, or voucher purchase and use;
                </li>
                <li>favourites (e.g. your favourite salon or gym);</li>
                <li>account settings;</li>
                <li>social login information;</li>
                <li>securely saved payment methods.</li>
                <li>
                  We may also collect information, if you provide it, about your
                  preferences, referrals, or how you found out about our
                  Services, or other user generated content (e.g. profile photo,
                  reviews).
                </li>
              </ul>
            </TermParagraph>
            <TermParagraph>
              <strong>
                We use this account and related information to manage your
                account with us, including to
              </strong>
              <ul>
                <li>maintain your BookingCampus account;</li>
                <li>provide customer support;</li>
                <li>
                  enable you to contact, order from and make bookings with our
                  Partners;
                </li>
                <li>
                  enable you to make and pay for orders and bookings with your
                  account;
                </li>
                <li>
                  if a Partner offers home services and you select the option,
                  to enable you to book appointments at the address you provide;
                </li>
                <li>
                  provide you with a personalised experience, including
                  recommending features, services, products and Partners we
                  think you may be interested in.
                </li>
              </ul>
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>
              If you order any products from a Partner's shop
            </ChildTitle>
            <TermParagraph>
              When you order any products from a Partner's shop, we may collect
              your:
              <ul>
                <li>
                  order details and purchase records, including any related
                  correspondence via our Services;
                </li>
                <li>cancellation, return and refund records;</li>
                <li>securely saved payment methods; and</li>
                <li>your product reviews or ratings.</li>
              </ul>
            </TermParagraph>
            <TermParagraph>
              <strong>
                We use your account and order information to enable our Partners
                to fulfil and manage your orders, including to
              </strong>
              <ul>
                <li>
                  enable you to contact and purchase products from our Partners,
                  including making payments,
                </li>
                <li>
                  share your contact and order information with the Partner you
                  ordered from, so the Partner can fulfil and manage your order
                  (including processing any cancellations, returns or refunds),
                  and communicate with you about your order.
                </li>
                <li>
                  store your order, cancellation, return and refund records.
                </li>
              </ul>
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>If you work for a Partner</ChildTitle>
            <TermParagraph>
              If you work for our Partner (e.g. as an employee, contractor, or
              worker), we may collect <strong>contact</strong> and{" "}
              <strong>company</strong> information from you:
            </TermParagraph>
            <TermParagraph>
              <strong>Contact</strong> information may include:
              <ul>
                <li>first and last name,</li>
                <li>role title,</li>
                <li>company name,</li>
                <li>email address,</li>
                <li>contact number,</li>
                <li>log-in details,</li>
                <li>
                  KYC and ID verification results (we do not keep any underlying
                  documents).
                </li>
              </ul>
            </TermParagraph>
            <TermParagraph>
              <strong>Company</strong> information may include:
              <ul>
                <li>company address,</li>
                <li>business type (e.g. salon, spa, gym),</li>
                <li>consultation form content,</li>
                <li>historical and future appointments,</li>
                <li>point of sale data,</li>
                <li>
                  payment processing data (we don't see this, only our payment
                  processors do),
                </li>
                <li>reporting and analytics,</li>
                <li>Client data and promotions;</li>
                <li>pictures and marketing materials.</li>
              </ul>
            </TermParagraph>
            <TermParagraph>
              <i>
                We also process other information on behalf of your company that
                is not personal data (for example, financial data and product
                inventory).
              </i>
            </TermParagraph>
            <TermParagraph>
              <strong>
                We use this contact and company information to manage the
                Partner's account with us, including to
              </strong>
              <ul>
                <li>maintain the Partner's account,</li>
                <li>
                  provide Partner customer support, including technical support
                  for our suite of business tools,
                </li>
                <li>
                  enable the Partner to use our suite of business tools,
                  including managing staff and appointment scheduling,
                </li>
                <li>
                  enable the Partner to make transactions and manage payments,
                </li>
                <li>
                  enable the Partner to manage marketing promotions via the
                  Services,
                </li>
                <li>
                  enable the Partner to list on the BookingCampus's marketplace
                </li>
                <li>
                  enable the Partner to manage inventory and use point of sale
                  devices,
                </li>
                <li>
                  may contact Partner staff to enable them to create a
                  Partner-specific account with appropriate authorisations, and
                  to manage those accounts.
                </li>
              </ul>
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>
              If we send you offers, updates or other marketing material from us
            </ChildTitle>
            <TermParagraph>
              We may collect <strong>contact</strong> information (as explained
              above), like your name and email address.
            </TermParagraph>
            <TermParagraph>
              We may also collect <strong>marketing preferences</strong>, which
              are our records of what information you would or would not like to
              receive from us, and if you have opted out of any direct
              marketing.
            </TermParagraph>
            <TermParagraph>
              <strong>
                We use this contact and marketing preference information to
              </strong>
              <ul>
                <li>
                  send you offers, updates, promotions, newsletter(s), insights
                  and other marketing material.
                </li>
                <li>
                  send you with a personalised marketing, including offers,
                  features, services, products and Partners we think you may be
                  interested in.
                </li>
                <li>
                  If you work for a company we are interested in partnering
                  with, we may contact you about our Services and opportunities
                  with BookingCampus.
                </li>
                <li>
                  Contact you to ask for feedback, including through surveys and
                  other marketing research.
                </li>
              </ul>
            </TermParagraph>
            <TermParagraph>
              <strong>You can opt-out of further marketing at any time</strong>{" "}
              by selecting the “unsubscribe” link at the end of all our
              promotional updates.
            </TermParagraph>
          </TermParagraph>
        </TermSection>
        <TermSection title="What do each of these legal reasons mean?">
          <TermParagraph>
            We must have a relevant legal justification, called a 'lawful basis'
            for each way in which we use your personal information.
          </TermParagraph>
          <TermParagraph>
            Lawful bases may include <strong>consent</strong>, a{" "}
            <strong>contract</strong> with you (as a data subject), compliance
            with our <strong>legal obligations</strong> and our{" "}
            <strong>specified legitimate interests</strong>.
          </TermParagraph>
          <TermParagraph>
            <strong>Consent</strong>
          </TermParagraph>
          <TermParagraph>
            We'll use your personal information to send you promotional or
            marketing content (for example, our insight updates or newsletters),
            if you have given us consent to us doing so, where required by law.
            We may also send direct marketing based on our legitimate interests
            (see below).
          </TermParagraph>
          <TermParagraph>
            <strong>You can opt-out of further marketing at any time</strong> by
            selecting the “unsubscribe” link at the end of all our promotional
            updates and marketing to you.
          </TermParagraph>
          <TermParagraph>
            We also rely on consent for some of the cookies we use (see our{" "}
            <a
              href="#cookie-notice"
              className="break-words"
              rel="noreferrer"
            >
              Cookies Notice
            </a>{" "}
            for more detail).
          </TermParagraph>
          <TermParagraph>
            <strong>Contract</strong>
          </TermParagraph>
          <TermParagraph>
            We collect, store and process your personal information if it is
            necessary for performing a contract you have with us (for example,
            our{" "}
            <a
              href="/terms-of-use"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href="/terms-of-service"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              Terms of Service
            </a>
            ), or where you have asked us to take specific steps before entering
            into that contract. This includes notifying you about changes to our
            Services.{" "}
            <i>
              This does not include any agreement we have with a Partner,
              whether you are a Booker or Partner staff – this is based on our
              legitimate interests (see below)
            </i>
            .
          </TermParagraph>
          <TermParagraph>
            <strong>Legal obligation</strong>
          </TermParagraph>
          <TermParagraph>
            We may need to process any of your personal information to comply
            with our legal obligations, including under applicable UK law,
            and/or any court orders. This may include compliance with
            know-your-client and anti-money laundering rules.
          </TermParagraph>
          <TermParagraph>
            <strong>Legitimate interests</strong>
          </TermParagraph>
          <TermParagraph>
            We may process your personal information if it is necessary for our
            legitimate interests or the legitimate interests of a third party,
            provided those interests are not outweighed by your rights and
            interests.
          </TermParagraph>
          <TermParagraph>
            Our legitimate interests include:
            <ul>
              <li>
                <strong>
                  Administering, improving and expanding our Site and services
                </strong>
                <ul>
                  <li>Keeping our records updated.</li>
                  <li>Gaining insights on how you use our Services.</li>
                  <li>Gaining your feedback and reviews,</li>
                  <li>Delivering, developing and improving our Services.</li>
                  <li>
                    Enhancing, customising or modifying our Services and
                    communications.
                  </li>
                  <li>Implementing and improving our security measures</li>
                  <li>
                    Growing our business and informing our marketing strategy.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Marketing</strong>
                <ul>
                  <li>
                    Marketing and promoting our Services to a company you work
                    for or provide services to.
                  </li>
                  <li>
                    measuring or understanding the effectiveness of advertising
                    we serve to you and others and delivering relevant
                    advertising to you (including when you visit other
                    websites).
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  Fulfilling agreements with another organisation{" "}
                </strong>
                <ul>
                  <li>
                    Complying with or enforcing any agreement BookingCampus has
                    (or that you may have, or example any extra Partner-specific
                    terms) with a Partner you have purchased goods or services
                    from as a Client.
                  </li>
                  <li>
                    Complying with or enforcing any agreement we may have with a
                    company you work for or provide services to (for example, if
                    you work at a Partner).
                  </li>
                </ul>
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            In each case, these legitimate interests are only valid if they are
            not outweighed by your rights and interests. If you would like
            further information about how we assess our legitimate interests,
            please contact us at{" "}
            <a
              href="mailto:support@BookingCampus.com"
              className="break-words"
              rel="noreferrer"
            >
              support@BookingCampus.com
            </a>
            .
          </TermParagraph>
          <TermParagraph>
            We also use different types of cookies on our Site – we explain this
            in the{" "}
            <a
              href="#cookie-notice"
              className="break-words"
              rel="noreferrer"
            >
              Cookies Notice
            </a>
            .
          </TermParagraph>
        </TermSection>
        <TermSection title="Who do we share your information with?">
          <TermParagraph>
            <ChildTitle>If you are a Client</ChildTitle>
            <TermParagraph>
              We will share your information with{" "}
              <strong>our Partners if you have asked us to</strong> (for
              example, if you want to connect with, order from or make a booking
              with one of our Partners). We may share your contact and account
              information, and they will receive any other information you
              provide through BookingCampus (for example, completing a Partner's
              consultation form). Partners may use this information to manage
              your communications, orders and bookings with them.{" "}
            </TermParagraph>
            <TermParagraph>
              If you leave a public review for a Partner (or any of their
              products in their shop on our Services), your rating, review,
              review date, and first name and last initial will be published on
              BookingCampus. Other BookingCampus users and members of the public
              may be able to see your reviews. Reviews may be published or
              syndicated on third party websites. Public reviews will survive
              the termination or expiry of the BookingCampus Terms of service.
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>If you work for a Partner</ChildTitle>
            <TermParagraph>
              BookingCampus users may rate the Partner you work at or for, and
              may rate you as an individual. Your individual ratings may be
              shared with the relevant Partner, and may be publicly available on
              our Services.
            </TermParagraph>
            <TermParagraph>
              We may publish limited Partner information (including individual
              and business public reviews and ratings) to other websites, for
              example so it can be included in search results or advertising
              (including online and offline advertising and promotional media,
              including, but not limited to Google, Google AdWords, Facebook
              Ads, Twitter, email distribution lists, and third-party business
              partners and affiliates).
            </TermParagraph>
          </TermParagraph>
          <TermParagraph>
            <ChildTitle>
              If BookingCampus processes your information as a Client, Partner
              staff, or otherwise
            </ChildTitle>
            <TermParagraph>
              We may also share your information with:
              <ul>
                <li>
                  any member of our company group, which means our ultimate
                  holding company and its subsidiaries, and/or our subsidiaries
                  who may support our processing of personal information under
                  this Notice.
                </li>
                <li>
                  our <strong>service providers</strong>, organisations who
                  process your personal data on our behalf and in accordance
                  with our instructions and the data protection law.
                  <ul>
                    <li>
                      This includes supporting the services we offer through the
                      Site, in particular those providing website and data
                      hosting services, distributing communications we send,
                      supporting or updating marketing lists, customer support,
                      facilitating feedback on our services, digital invoicing
                      and payment processing providers who provide secure
                      payment processing services, and providing IT support
                      services from time to time.
                    </li>
                    <li>
                      These organisations (which may include third party
                      suppliers, agents, sub-contractors and/or other companies
                      in our group) will only use your information to the extent
                      necessary to perform their support functions.
                    </li>
                  </ul>
                </li>
                <li>
                  if you create a Partner Account with us by using a{" "}
                  <strong>Referral Link</strong> the Partner who the Referral
                  Link relates to may view the progression of your Partner
                  Account
                </li>
                <li>
                  if we run{" "}
                  <strong>
                    surveys, competitions, promotional campaigns, offers or
                    other occasional activities and you opt-in
                  </strong>
                  , we may share certain information with our commercial
                  partners (for example, if you chose to enter into a prize draw
                  we run with a third party). We will provide more detailed
                  privacy information at the time.
                </li>
                <li>
                  our <strong>auditors, legal advisers</strong> and{" "}
                  <strong>other professional advisers</strong>;
                </li>
                <li>
                  <strong>if we sell or buy any business or assets</strong>, in
                  which case we will disclose your personal data to the
                  prospective seller or buyer.
                </li>
                <li>
                  any person to whom disclosure is necessary to enable us to
                  <strong>
                    protect our rights, property, or safety, our clients, or
                    other third parties
                  </strong>
                  , and{" "}
                  <strong>
                    to enforce our rights under this Notice or under any
                    agreement
                  </strong>{" "}
                  (for example, our{" "}
                  <a
                    href="/terms-of-use"
                    target="_blank"
                    className="break-words"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    href="/terms-of-service"
                    target="_blank"
                    className="break-words"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                  ) with you. This includes exchanging information with other
                  companies and organisations for the purposes of detecting and
                  preventing fraud and cyber-crime.
                </li>
                <li>
                  if required to do so by <strong>court order</strong> or if we
                  are under a duty to disclose your information in order to
                  comply with (and/or where we believe we are under a duty to
                  comply with) any <strong>legal obligation</strong>. This
                  includes exchanging information with law enforcement agencies,
                  regulators, or other similar government bodies.
                </li>
              </ul>
            </TermParagraph>
          </TermParagraph>
        </TermSection>
        <TermSection title="Where do we store your information?">
          <TermParagraph>
            BookingCampus operates globally, including in the United States,
            Canada, United Kingdom, Australia, the United Arab Emirates,
            Netherlands and other countries in the European Economic Area ("
            <strong>EEA</strong>"). We generally don't share personal data
            between group companies.
          </TermParagraph>
          <TermParagraph>
            We may transfer your personal information outside the UK / EEA:
            <ul>
              <li>in order to store it;</li>
              <li>in order to enable us to provide our Services to you;</li>
              <li>
                in order to facilitate the operation of our businesses, where it
                is in our legitimate interests and we have concluded these are
                not overridden by your rights; and
              </li>
              <li>where we are legally required to do so.</li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            We will put legal protections in place to safeguard personal data
            transfers in compliance with data protection laws.
          </TermParagraph>
          <TermParagraph>
            We update our partners and service providers as we grow, and will
            update this Notice regularly.
            <i>
              For more information about how we currently transfer and protect
              data, please contact{" "}
              <a
                href="mailto:support@BookingCampus.com"
                className="break-words"
                rel="noreferrer"
              >
                support@BookingCampus.com
              </a>
              .
            </i>
          </TermParagraph>
        </TermSection>
        <TermSection title="How do we protect your information?">
          <TermParagraph>
            All information you provide to us is securely stored. Our website
            uses secure end-to-end encryption to protect your information. All
            connections into our platform are secured using industry standard
            security and encryption.
          </TermParagraph>
          <TermParagraph>
            All data we capture is stored in secured databases and data storage
            systems with strict access limitations. All data access requests are
            logged and monitored in accordance with any threat detection
            policies.
          </TermParagraph>
          <TermParagraph>
            Unfortunately, the transmission of information via the internet is
            not completely secure. We do our best to protect your personal
            information, but we cannot guarantee the security of your data
            transmitted to us – even if there is a special notice that a
            particular transmission (for example, credit card information) is
            encrypted. Any transmission is at your own risk. Once we have
            received your information, we use strict procedures and security
            features to try to prevent unauthorised access.
          </TermParagraph>
        </TermSection>
        <TermSection title="Payment processing">
          <TermParagraph>
            Payments made on our Services are made through our payment gateway
            provider, Square. You will be providing credit or debit card
            information directly to Square, which operates a secure server to
            process payment details, encrypting your credit/debit card
            information and authorising payment. BookingCampus can view
            pseudonymized cardholder and card details. In addition,
            BookingCampus stores the following card information:
            <ul>
              <li>card brand,</li>
              <li>card holder name,</li>
              <li>card's last four digits,</li>
              <li>card expiry month and expiry year. </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            Information which you supply to Adyen (or Checkout) is not within
            our control and is subject to{" "}
            <a
              href="https://squareup.com/us/en/legal/general/privacy"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              https://squareup.com/us/en/legal/general/privacy
            </a>
            .
          </TermParagraph>
        </TermSection>
        <TermSection title="External sites">
          <TermParagraph>
            BookingCampus may, from time to time, contain links to external
            sites. If you follow a link to any of these websites, please note
            that these websites have their own privacy policies. BookingCampus
            does not accept any responsibility or liability for these policies
            or any content on external sites and does not necessarily endorse
            the views expressed within them. BookingCampus has no control over
            the availability of any of these websites. Please check each site's
            policies before you submit any personal information to these
            websites.
          </TermParagraph>
        </TermSection>
        <TermSection title="How long is your information kept for?">
          <TermParagraph>
            We will generally process personal information:
            <ul>
              <li>
                for as long as it is necessary for the purpose(s) for which it
                was originally collected (for example, holding it on behalf of a
                Partner, or for as long as you have an account with us), and
              </li>
              <li>
                for up to six years after that to identify any issues and
                resolve any legal proceedings.
              </li>
            </ul>
          </TermParagraph>
          <TermParagraph>
            We may retain your personal data for a longer period in the event of
            a complaint, if we reasonably believe there is a prospect of legal
            proceedings, or we are aware of pending or ongoing legal
            proceedings. In some circumstances, we may keep data longer if
            applicable law says we have to.
          </TermParagraph>
          <TermParagraph>
            If you have opted to receive insights, newsletters, or other
            promotional material, but later decide to opt out from receiving
            these updates, or object to any other processing of your personal
            information, we may keep a record of your opt-out or objection so we
            can respect your preferences.
          </TermParagraph>
          <TermParagraph>
            We may keep aggregated, anonymised or pseudonymized data – for
            example, for reporting and analytics – for longer periods.
          </TermParagraph>
        </TermSection>
        <TermSection title="Aggregated data">
          <TermParagraph>
            In some circumstances we will anonymise your personal data (so that
            it can no longer be associated with you and you cannot be
            re-identified). This anonymised data (for example, aggregated
            statistics) is no longer personal data, and we may keep and use this
            anonymised information indefinitely without further notice to you.
          </TermParagraph>
          <TermParagraph>
            We use this to help us provide, develop and improve our services,
            including to:
            <ul>
              <li>better understand how people use our Services,</li>
              <li>
                provide our Partners and Clients with information about our
                Services, including user statistics (e.g. total numbers, broad
                demographics statistics), and
              </li>
              <li>develop useful insights and improvements to the Services.</li>
            </ul>
          </TermParagraph>
        </TermSection>
        <TermSection title="What rights do you have with your personal information?">
          <TermParagraph>
            In certain circumstances, you have the following rights:
            <ol>
              <li></li>
              <li>
                to be provided with a <strong>copy</strong> of your personal
                information held by us;
              </li>
              <li>
                to request the <strong>correction</strong> or{" "}
                <strong>deletion</strong> of your personal information held by
                us;
              </li>
              <li>
                to request that we <strong>restrict</strong> the processing of
                your personal information (while we verify or investigate your
                concerns with this information, for example);
              </li>
              <li>
                to <strong>object</strong> to the further processing of your
                personal information, including the right to object to
                marketing;
              </li>
              <li>
                to request that your provided personal data be{" "}
                <strong>moved</strong> to a third party, and
              </li>
              <li>
                to <strong>withdraw consent</strong>.
              </li>
            </ol>
          </TermParagraph>
          <TermParagraph>
            If you wish to exercise any of these rights in relation to the
            personal information we hold about you, you can contact us at{" "}
            <a
              href="mailto:support@BookingCampus.com"
              className="break-words"
              rel="noreferrer"
            >
              support@BookingCampus.com
            </a>
            . If you have any concerns, you have the right to lodge a complaint
            with a data protection supervisory authority.
          </TermParagraph>
          <TermParagraph>
            The Information Commissioner's Office (ICO) is the supervisory
            authority in the UK and can provide further information about your
            rights, an organisation's obligation in relation to your personal
            information, as well as deal with any complaints that you may have.
            You can visit their website at{" "}
            <a
              href="www.ico.org.uk"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              www.ico.org.uk
            </a>
            .
          </TermParagraph>
          <TermParagraph>
            If you are outside the UK, you can find your local data protection
            authority{" "}
            <a
              href="https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080"
              target="_blank"
              className="break-words"
              rel="noreferrer"
            >
              here
            </a>
            .
          </TermParagraph>
        </TermSection>
        <TermSection title="Updating this Privacy notice">
          <TermParagraph>
            This Notice was last updated on 11 Feb 2022.
          </TermParagraph>
          <TermParagraph>
            We may update this Notice from time to time and will post any
            changes on this page.
          </TermParagraph>
          <TermParagraph>
            If we make any substantive changes, we will notify you through
            email, or website pop-ups within our Site.
          </TermParagraph>
        </TermSection>
        <TermSection id="cookie-notice" title="Cookie notice">
          <TermParagraph>
            Our Sites use cookies and/or other similar technologies such as
            device-IDs, in-App codes, pixel tags and web beacons to collect and
            store certain information. These typically involve pieces of
            information or code that a website transfers to or accesses from
            your computer hard drive or mobile device to store and sometimes
            track information about you. Cookies and similar technologies enable
            you to be remembered when using that computer or device to interact
            with websites and online services and can be used to manage a range
            of features and content as well as storing searches and presenting
            personalised content.
          </TermParagraph>
          <TermParagraph>
            Our Sites use cookies and similar technologies either alone or in
            combination with each other to create a unique device ID, and to
            distinguish you from other users of our Sites. This helps us to
            provide you with a good experience when you browse our Sites and
            also allows us to improve our Sites.
          </TermParagraph>
          <TermParagraph>
            A number of cookies and similar technologies we use last only for
            the duration of your web or app session and expire when you close
            your browser or exit one of our Sites. Others are used to remember
            you when you return to one of our Sites and will last for longer.
          </TermParagraph>
        </TermSection>
      </TermContainer>
    </LayoutBase>
  );
};

export default PrivacyPolicy;
