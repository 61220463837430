import { ContentXl, Content3Xl } from "components/misc/Layouts";
import HeaderBase, { NavLinks } from "components/headers/light.js";

import logoNeutralImageSrc from "images/bc/logo-x.svg";
import tw from "twin.macro";
import { OutlinePrimaryButton } from "components/misc/Buttons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Affix } from "antd";
import { useState } from "react";

const Container = styled.div`
  ${tw`py-2 fixed z-10 w-full top-0 bg-white shadow-md`}
  &.neutral:not(.affixed) {
    ${tw`shadow-none`}
  }
`;
const Header = tw(HeaderBase)`max-w-none`;
const NavLink = styled(Link)`
  ${tw`
    text-lg my-4 lg:text-sm lg:mx-6 lg:my-0
    font-semibold tracking-wide transition duration-300
    pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
    lg:text-bc-neutral1 lg:hocus:text-bc-neutral1 hover:border-bc-neutral1
  `}
`;
const LogoLink = styled(NavLink)`
  ${tw`
    flex items-center font-black border-b-0 text-2xl! ml-0!
    text-gray-100 hocus:text-gray-300
  `};

  img {
    ${tw`w-10 mr-3`}
  }
`;
const LoginButton = tw(OutlinePrimaryButton)`px-8 py-2 lg:ml-6`;

export default ({ variant }) => {
  const [affixedNav, setAffixedNav] = useState(false);
  const logoLink = (
    <LogoLink to="/">
      <img src={logoNeutralImageSrc} alt="Logo" style={{ width: 144 }} />
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <NavLink className={!affixedNav && variant} to="/contact-us">
        Contact us
      </NavLink>
      <LoginButton>Login</LoginButton>
    </NavLinks>,
  ];
  return (
    <Affix onChange={setAffixedNav}>
      <Container className={affixedNav ? `${variant} affixed` : variant}>
        {variant ? (
          <Content3Xl>
            <Header logoLink={logoLink} links={navLinks} />
          </Content3Xl>
        ) : (
          <ContentXl>
            <Header logoLink={logoLink} links={navLinks} />
          </ContentXl>
        )}
      </Container>
    </Affix>
  );
};
