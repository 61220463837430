import React from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import svgDecoratorBlob from "images/bc/bg-contact.svg";
import { ContentXl } from "components/misc/Layouts";
import { SectionHeading } from "components/misc/Headings";
import { SecondaryButton } from "components/misc/Buttons";
import FAQIconImage from "images/bc/illu_phone.png";
import { sendMail } from "booking-campus/services/mail.service";

// Amplify libraries
// import Amplify, { API } from "aws-amplify";
// import awsconfig from "../aws-exports";
// Amplify.configure(awsconfig);

const Container = styled.div`
  ${(props) => css`
    background-image: url("${props.background}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `}
  ${tw`relative`}
`;
const Content = tw(ContentXl)`pt-8`;
const Heading = tw(SectionHeading)`w-full text-white text-left`;
const FormContainer = styled.div`
  ${tw`px-0 py-16 xl:(py-20 pl-12 pr-0) text-white relative`}
  h3 {
    ${tw`text-2xl sm:text-2xl font-bold text-bc-white1 mt-6 mb-56`}
  }
  h4 {
    ${tw`text-bc-white1 mt-2 -mb-8`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-base font-medium tracking-wide border-b-2 py-2 hocus:border-bc-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-white text-opacity-40`}
    }
  }
`;
const TwoColumn = tw.div`flex flex-col md:flex-row`;
const Column = tw.div`block w-full md:w-1/2 xl:w-5/12 flex-shrink-0 xl:pr-12`;
const ColumnFlex1 = tw(Column)`flex-grow xl:pr-0`;
const Image = tw.img`block bg-center w-1/2 pr-10 xl:pr-0 md:w-full pt-10 xl:pt-20 mx-auto md:mx-0`;
const InputContainer = tw.div`relative py-4`;
const Label = tw.label`tracking-wide font-semibold text-sm`;
const Input = tw.input`border-bc-secondary1`;
const TextArea = tw.textarea`border-bc-secondary1 resize-none`;
const ButtonContainer = tw.div`text-center`;
const SubmitButton = tw(SecondaryButton)`mt-4`;

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      notes: "",
      showInputsWarningMessage: false,
      showThanksForFeedbackMessage: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleFormSubmit(event) {
    event.preventDefault();

    if (
      this.state.name.trim() === "" ||
      this.state.email.trim() === "" ||
      this.state.notes.trim() === ""
    ) {
      this.setState({ showInputsWarningMessage: true });
      return;
    }

    this.setState({ showInputsWarningMessage: false });
    this.setState({ showThanksForFeedbackMessage: true });

    this.invokeApiSendEmailContactUs();
  }

  async invokeApiSendEmailContactUs() {
    const { email, name, notes } = this.state;
    sendMail({ email, name, notes }).then(
      () => {
        console.log("ContactUs email has been sent successfully!");
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {
    return (
      <Container background={svgDecoratorBlob}>
        <Content>
          <TwoColumn>
            <Column>
              <Image src={FAQIconImage} />
            </Column>
            <ColumnFlex1>
              <FormContainer>
                <Heading>Contact Us</Heading>
                {this.state.showInputsWarningMessage && (
                  <h4 style={{ color: "orangered" }}>
                    Please complete all required fields!
                  </h4>
                )}
                {this.state.showThanksForFeedbackMessage && (
                  <h3>
                    Thank you for your feedback, we will get back to you soon!
                  </h3>
                )}
                {!this.state.showThanksForFeedbackMessage && (
                  <form onSubmit={this.handleFormSubmit} autoComplete="off">
                    <InputContainer>
                      <Label htmlFor="name-input">Your Name</Label>
                      <Input
                        id="name-input"
                        type="text"
                        name="name"
                        placeholder="e.g. John Doe"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                      />
                    </InputContainer>

                    <InputContainer style={{ marginTop: 1 }}>
                      <Label htmlFor="email-input">Your Email Address</Label>
                      <Input
                        id="email-input"
                        type="email"
                        name="email"
                        placeholder="e.g. john@mail.com"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                      />
                    </InputContainer>

                    <InputContainer tw="flex-1">
                      <Label htmlFor="name-input">Your Message</Label>
                      <TextArea
                        id="message-input"
                        name="notes"
                        placeholder="e.g. Details about your question"
                        value={this.state.notes}
                        onChange={this.handleInputChange}
                        rows={5}
                      />
                    </InputContainer>

                    <ButtonContainer>
                      <SubmitButton type="submit" value="Submit">
                        Submit
                      </SubmitButton>
                    </ButtonContainer>
                  </form>
                )}
              </FormContainer>
            </ColumnFlex1>
          </TwoColumn>
        </Content>
      </Container>
    );
  }
}

export default ContactUs;
