import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "components/misc/Headings.js";
import { ReactComponent as ArrowLeftIcon } from "images/bc/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "images/bc/icons/arrow-right.svg";
import Customer01IconImage from "images/bc/customers/customer01.jpg";
import Customer02IconImage from "images/bc/customers/customer02.jpg";
import Customer03IconImage from "images/bc/customers/customer03.jpg";
import Customer04IconImage from "images/bc/customers/customer04.jpg";
import Customer05IconImage from "images/bc/customers/customer05.jpg";
import Customer06IconImage from "images/bc/customers/customer06.jpg";
import Customer07IconImage from "images/bc/customers/customer07.jpg";
import Customer08IconImage from "images/bc/customers/customer08.jpg";
import Customer09IconImage from "images/bc/customers/customer09.jpg";
import Customer10IconImage from "images/bc/customers/customer10.jpg";

import "slick-carousel/slick/slick.css";
import { ContentXl } from "components/misc/Layouts";

const Container = tw.div`relative mt-16`;
const Content = tw(ContentXl)`py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center text-bc-neutral1`;
const TestimonialSliderContainer = tw.div`mt-24`;
const TestimonialSlider = styled(Slider)`relative`;
const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded`}
  }
`;
const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-6/12 flex flex-col justify-between`;
const Quote = styled.blockquote`
  ${tw`relative p-6 pb-16 md:p-8 md:pb-16 lg:py-14 lg:pb-16 mt-4 md:mt-0 text-center md:text-justify font-medium text-xl lg:text-2xl text-bc-neutral2`}
  ${css`
    &::before,
    &::after {
      position: absolute;
      font-size: 4.5rem;
      ${tw`text-transparent bg-clip-text bg-gradient-to-t from-bc-linear3 via-bc-linear2 to-bc-linear1`}
    }
    &::before {
      content: "“";
      top: 0;
      transform: translate(-100%);
    }
    &::after {
      content: "”";
      bottom: 0;
      right: 0;
    }
  `}
`;
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-bc-neutral1`;
const CustomerTitle = tw.p`font-medium text-sm text-bc-neutral3`;
const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-[1]`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="right-0">
    <button {...props}>
      <ArrowRightIcon />
    </button>
  </SliderControlButtonContainer>
);

const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="left-0">
    <button {...props}>
      <ArrowLeftIcon />
    </button>
  </SliderControlButtonContainer>
);

export default () => {
  /*
   * You can modify the testimonials shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  const testimonials = [
    {
      imageSrc: Customer01IconImage,
      quote:
        "The online booking system is so convenient! I can easily schedule appointments from my phone, and it saves me a lot of time.",
      customerName: "Alisa Cuppy",
      customerTitle: "Founder, EventsNYC",
    },
    {
      imageSrc: Customer02IconImage,
      quote:
        "I love that I can see the availability of my favorite stylist and choose a time that works for me. It makes planning my visits so much simpler.",
      customerName: "Alisa Cuppy",
      customerTitle: "Founder, EventsNYC",
    },
    {
      imageSrc: Customer03IconImage,
      quote:
        "The user interface is clean and easy to navigate. I had no trouble booking my appointment, even as a first-time user.",
      customerName: "Alisa Cuppy",
      customerTitle: "Founder, EventsNYC",
    },
    {
      imageSrc: Customer04IconImage,
      quote:
        "I appreciate the email or text reminders I receive before my appointment. It helps me stay on top of my schedule.",
      customerName: "Alisa Cuppy",
      customerTitle: "Founder, EventsNYC",
    },
    {
      imageSrc: Customer05IconImage,
      quote:
        "It's great that I can select the specific service I want and any add-ons or upgrades all in one place. It's very convenient.",
      customerName: "Alisa Cuppy",
      customerTitle: "Founder, EventsNYC",
    },
    {
      imageSrc: Customer06IconImage,
      quote:
        "The system's responsiveness is impressive; it didn't lag or crash during my booking process.",
      customerName: "Alisa Cuppy",
      customerTitle: "Founder, EventsNYC",
    },
    {
      imageSrc: Customer07IconImage,
      quote:
        "The ability to reschedule or cancel appointments online is a lifesaver. I don't have to call the salon, which is very convenient.",
      customerName: "Alisa Cuppy",
      customerTitle: "Founder, EventsNYC",
    },
    // {
    //   imageSrc: Customer08IconImage,
    //   quote:
    //     "I wish there were more options for customising my appointment, like choosing a specific treatment time or a different stylist.",
    //   customerName: "Alisa Cuppy",
    //   customerTitle: "Founder, EventsNYC",
    // },
    {
      imageSrc: Customer09IconImage,
      quote:
        "It would be helpful to have a feature that shows estimated wait times or real-time updates if my appointment is running behind schedule.",
      customerName: "Alisa Cuppy",
      customerTitle: "Founder, EventsNYC",
    },
    {
      imageSrc: Customer10IconImage,
      quote:
        "I'd like to see more information about the services offered and detailed descriptions of what they include on the booking platform.",
      customerName: "Alisa Cuppy",
      customerTitle: "Founder, EventsNYC",
    },
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Our Awesome Customers</HeadingTitle>
        </HeadingInfoContainer>
        <TestimonialSliderContainer>
          <TestimonialSlider
            nextArrow={<NextArrow />}
            prevArrow={<PreviousArrow />}
            autoplay
            autoplaySpeed={5000}
            infinite
          >
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index}>
                <ImageContainer>
                  <img
                    src={testimonial.imageSrc}
                    alt={testimonial.customerName}
                  />
                </ImageContainer>
                <TextContainer>
                  <Quote>{testimonial.quote}</Quote>
                  {false && (
                    <CustomerInfo>
                      <CustomerName>{testimonial.customerName}</CustomerName>
                      <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                    </CustomerInfo>
                  )}
                </TextContainer>
              </Testimonial>
            ))}
          </TestimonialSlider>
        </TestimonialSliderContainer>
      </Content>
    </Container>
  );
};
