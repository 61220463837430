export const DATE_TIME_FORMAT = "MMM Do YYYY HH:mm";
export const DISABLED_HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 22, 23];
export const CONTACT_US_EMAIL_TEMP = "template_3xckm3l";
export const RESERVATION_EMAIL_TEMP = "template_3xckm3l";

export const TRIAL_DURATIONS = {
  month: {
    days: 30,
    name: "month",
  },
  week: {
    days: 7,
    name: "week",
  },
};
export const TRIAL_DURATION_APPLY =
  process.env.REACT_APP_TRIAL_DURATION_APPLY || "week";

export const STRIPE_CARD_OPTIONS = {
  hidePostalCode: true,
  hideIcon: true,
  style: {
    base: {
      fontSize: "1rem",
      fontFamily:
        "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "rgba(0, 0, 0, 0.25)",
      },
    },
    invalid: {},
  },
};

export const CURRENCY = "GBP";

export const STRIPE_ELEMENTS_OPTIONS = {
  locale: "en",
};

export const DurationType = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
};

export const TransactionStatus = {
  COMPLETED: "COMPLETED",
  PENDING: "PENDING",
  REQUIRES_CONFIRMATION: "REQUIRES_CONFIRMATION",
};

export const CardConfirmStatus = {
  REQUIRES_PAYMENT_METHOD: "requires_payment_method",
  REQUIRES_CAPTURE: "requires_capture",
  REQUIRES_ACTION: "requires_action",
  SUCCEEDED: "succeeded",
};
