import tw from "twin.macro";

import { ReactComponent as ArrowIcon } from "images/bc/icons/arrow-up.svg";
import { ReactComponent as MessagesIcon } from "images/bc/icons/messages.svg";
import { useEffect, useState } from "react";

const Container = tw.div`fixed w-10 bottom-[3rem] right-[1rem] z-10`;
const ControlButton = tw.button`rounded-full bg-bc-warning1 hover:bg-bc-warning2 text-center w-10 h-10`;
const ScrollToTopButton = tw(ControlButton)`pt-2 pb-[0.7rem] px-[0.7rem]`;
const MessagesButton = tw(ControlButton)`mt-2 p-2 opacity-0`;

export default () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Container>
        {showScrollButton && (
          <ScrollToTopButton onClick={scrollToTop}>
            <span>
              <ArrowIcon />
            </span>
          </ScrollToTopButton>
        )}
        <MessagesButton>
          <span>
            <MessagesIcon />
          </span>
        </MessagesButton>
      </Container>
    </>
  );
};
