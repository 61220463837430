import { Content3Xl } from "components/misc/Layouts";
import styled from "styled-components";
import tw from "twin.macro";

export const TermContainer = tw(
  Content3Xl
)`py-24 text-bc-neutral1 text-base text-justify`;
export const TermTitle = tw.h1`text-center font-bold mb-10 text-3xl lg:text-4xl xl:text-5xl`;
export const Section = tw.section`mb-10 last:mb-0`;
export const Title = tw.h2`text-2xl mb-3 capitalize font-semibold text-bc-primary1`;
export const ChildTitle = tw.h3`text-xl mt-3 mb-2 capitalize font-medium`;
export const GrandChildTitle = tw.p`font-bold italic`;
const Paragraph = styled.div`
  ${tw`mb-2 text-base`}
  ul {
    ${tw`list-disc ml-5`}
    & > li {
      & > ul {
        ${tw`list-circle`}
      }
    }
  }
  ol {
    ${tw`list-decimal ml-5`}
  }
  .break-words {
    ${tw`break-words`}
  }
  a {
    text-decoration: underline;
  }
`;
export const CenterParagraph = tw(Paragraph)`text-center font-bold my-3 mx-auto max-w-3xl`;

const TermSection = ({ title, children, ...props }) => {
  return (
    <Section {...props}>
      <Title>{title}</Title>
      {children}
    </Section>
  );
};

export default TermSection;

export const TermParagraph = ({ children }) => {
  return <Paragraph>{children}</Paragraph>;
};
