import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  Container as ContainerBase,
  ContentXl,
} from "components/misc/Layouts.js";
import checkboxCircleIconImage from "images/bc/icons/circle-checked.svg";
import disabledCheckboxCircleIconImage from "images/bc/icons/circle-checked-disabled.svg";
import svgDecoratorBlob from "images/bc/bg-plans.svg";
import free2MonthsIcon from "images/bc/free-2-months.svg";
import PlanModal from "./PlanModal";
import {
  DurationType,
  TRIAL_DURATIONS,
  TRIAL_DURATION_APPLY,
} from "booking-campus/helpers/constants";
import { formatCurrency } from "booking-campus/helpers/utils";

const Content = styled(ContentXl)`
  ${(props) => css`
    background-image: url("${props.background}");
    background-repeat: no-repeat;
    background-position-y: top;
    background-position-x: center;
  `}
  ${tw`pt-24`}
`;
const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(
  SectionHeading
)`w-full from-bc-300 via-bc-400 to-bc-500 text-white`;
const Description = tw(SectionDescription)`w-full text-gray-300 text-center`;
const PlanDurationSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto rounded-full bg-white bg-opacity-10 p-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-36 px-4 sm:px-8 py-3 rounded-full relative focus:outline-none text-lg font-bold text-bc-secondary1 transition duration-300`}
  ${(props) => props.active && tw`bg-white`}
`;
const LabelImage = styled.img`
  ${tw`absolute max-w-none top-0 left-0 hidden md:inline-block`}
  ${css`
    transform: translate(45%, -45%);
  `}
`;
const PlansContainer = tw.div`mt-10 pb-12 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-center text-bc-neutral1 font-medium`;
const Plan = styled.div`
  ${tw`w-full max-w-smd bg-white rounded-2xl shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:py-8 xl:px-16 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;
const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-row justify-center items-center`}
  }
  .name {
    ${tw`lg:text-lg xl:text-xl font-semibold uppercase tracking-wider text-center text-bc-neutral2`}
  }
  .duration {
    ${tw`text-bc-neutral3`}
  }
  .featuredText {
    ${tw`text-xl font-semibold px-3 rounded-full py-1 ml-3 uppercase bg-bc-secondary1 text-white leading-none text-center`}
  }
  .pricingContainer {
    ${tw`mt-4 text-center text-2xl font-medium`}
    .currentPrice {
      ${tw`w-full text-lg font-bold leading-none text-center`}
      .bigText {
        ${tw`text-4xl font-bold`}
      }
    }
    .oldPrice {
      ${tw`text-gray-500 text-lg line-through hidden sm:block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium text-gray-700 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`flex-1 border-t mt-5 mb-8 pt-6`}
  .feature {
    ${tw`flex items-start mt-4 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 flex-shrink-0`}
    }
    img {
      ${tw`w-6 h-6 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-base tracking-wide ml-3`}
    }
    .inline-block {
      display: inline-block;
    }
  }
`;
const PlanAction = tw.div`mt-4 text-center`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block tracking-wider px-4 md:px-6 w-full`}
`;
const PlanNote = tw.div`text-center`;
const Asterisk = tw.span`text-bc-danger1`;

export default ({
  subheading = "",
  heading = "Flexible Plans",
  description = "",
  plans = null,
  primaryButtonText = `Start One-${TRIAL_DURATIONS[TRIAL_DURATION_APPLY].name} Free Trial`,
  planDurations = [
    {
      name: DurationType.MONTHLY,
      shortName: "mo",
      text: "Month",
      switcherText: "Monthly",
      note: `Cancel anytime with no cancellation fees during One-${TRIAL_DURATIONS[TRIAL_DURATION_APPLY].name} Free Trial`,
    },
    {
      name: DurationType.YEARLY,
      shortName: "y",
      text: "Year",
      switcherText: "Yearly",
      labelImage: free2MonthsIcon,
      labelClassName: "yearly",
    },
  ],
}) => {
  const [activeDuration, setActiveDuration] = useState(planDurations[0]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();

  const defaultPlans = [
    {
      name: "Popular plan",
      iconImg: "",
      price: {
        [DurationType.YEARLY]: 299.99,
        [DurationType.MONTHLY]: 29.99,
      },
      oldPrice: {
        [DurationType.YEARLY]: undefined,
        [DurationType.MONTHLY]: undefined,
      },
      description: "",
      features: [
        "Unlimited appointments",
        "Email reminders",
        "Accept payments",
        "2-way calendar sync",
        "Basic appointment calendar",
        "1 staff",
        "Unlimited support",
      ],
      disabledFeatures: ["Administration Panel", "Client records"],
      url: "/",
    },
    {
      name: "Pro plan",
      iconImg: "",
      price: {
        [DurationType.YEARLY]: 599.99,
        [DurationType.MONTHLY]: 59.99,
      },
      oldPrice: {
        [DurationType.YEARLY]: undefined,
        [DurationType.MONTHLY]: undefined,
      },
      description: "",
      features: [
        "Unlimited appointments",
        "Email reminders",
        "Accept payments",
        "2-way calendar sync",
        "Pro appointment calendar",
        "Unlimited staff",
        "Unlimited support",
        "Administration Panel",
        "Client records",
      ],
      url: "/",
      featured: "BEST",
    },
  ];

  if (!plans) plans = defaultPlans;

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const handlePlanButtonClick = (plan) => {
    setSelectedPlan(plan);
    toggleModal();
  };

  return (
    <ContainerBase>
      <Content background={svgDecoratorBlob}>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
          <PlanDurationSwitcher>
            {planDurations.map((planDuration) => (
              <SwitchButton
                active={activeDuration.name === planDuration.name}
                key={planDuration.name}
                onClick={() => setActiveDuration(planDuration)}
              >
                {planDuration.switcherText}
                {planDuration.labelImage && (
                  <LabelImage
                    className={planDuration.className}
                    src={planDuration.labelImage}
                    alt=""
                  />
                )}
              </SwitchButton>
            ))}
          </PlanDurationSwitcher>
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                {plan.iconImg && (
                  <span>
                    <img src={plan.iconImg} alt="" />
                  </span>
                )}
                <span className="nameAndFeaturedContainer">
                  <span className="name">{plan.name}</span>
                  {plan.featured && (
                    <span className="featuredText">{plan.featured}</span>
                  )}
                </span>
                <div className="pricingContainer">
                  <span className="currentPrice">
                    <span className="bigText">
                      {formatCurrency(plan.price[activeDuration.name])}
                    </span>
                  </span>
                  {plan.oldPrice[activeDuration.name] && (
                    <span className="oldPrice">
                      {formatCurrency(plan.oldPrice[activeDuration.name])}
                    </span>
                  )}
                  <span className="duration"> / {activeDuration.text}</span>
                  {activeDuration.note && <Asterisk>*</Asterisk>}
                </div>
                {plan.description && (
                  <p className="description">{plan.description}</p>
                )}
              </PlanHeader>
              <PlanFeatures>
                {plan.features &&
                  plan.features.map((feature, index) => (
                    <li className="feature" key={index}>
                      <span>
                        <img
                          className="inline-block"
                          src={checkboxCircleIconImage}
                          alt="check"
                        />
                        <span className="text">{feature}</span>
                      </span>
                    </li>
                  ))}
                {plan.disabledFeatures &&
                  plan.disabledFeatures.map((feature, index) => (
                    <li className="feature" key={index}>
                      <span>
                        <img
                          className="inline-block"
                          src={disabledCheckboxCircleIconImage}
                          alt="check"
                        />
                        <span className="text">{feature}</span>
                      </span>
                    </li>
                  ))}
              </PlanFeatures>
              {activeDuration.note && (
                <PlanNote>
                  <Asterisk>* </Asterisk>
                  {activeDuration.note}
                </PlanNote>
              )}
              <PlanAction>
                <ActionButton
                  onClick={() =>
                    handlePlanButtonClick({
                      plan,
                      duration: activeDuration,
                    })
                  }
                >
                  {primaryButtonText}
                </ActionButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </Content>
      <PlanModal
        isOpen={modalIsOpen}
        toggleModal={toggleModal}
        selectedPlan={selectedPlan}
      />
    </ContainerBase>
  );
};
