import { Form } from "antd";
import { FormItem, InputField } from "booking-campus/base/Form";
import { PrimaryButton } from "components/misc/Buttons";
import { useEffect, useState } from "react";
import tw from "twin.macro";

export const SubmitButton = tw(PrimaryButton)`w-full`;

export default ({ initialValues, onSubmit }) => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(true);
  
  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values);
    } catch (errorInfo) {}
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
    >
      <FormItem
        label="Street address line 1"
        name="address1"
        rules={[{ required: true, message: "Please input your address" }]}
      >
        <InputField placeholder="Enter your address" />
      </FormItem>
      <FormItem label="Street address line 2 (Optional)" name="address2">
        <InputField placeholder="Enter your address" />
      </FormItem>
      <FormItem
        label="City"
        name="city"
        rules={[{ required: true, message: "Please input your city" }]}
      >
        <InputField placeholder="Enter city" />
      </FormItem>
      <FormItem
        label="Postcode"
        name="postcode"
        rules={[{ required: true, message: "Please input your postcode" }]}
      >
        <InputField placeholder="Enter postcode" />
      </FormItem>
      <FormItem>
        <SubmitButton onClick={handleSubmit} disabled={!submittable}>
          Continue
        </SubmitButton>
      </FormItem>
    </Form>
  );
};
