import axios from "axios";

export const sendMail = (formData, content) => {
  const {
    REACT_APP_API_BASE_URL,
    REACT_APP_EMAIL_RECEIVER,
  } = process.env;
  const { name, phone, email, notes } = formData;

  return axios.post(
    `${REACT_APP_API_BASE_URL}/api/emails`,
    {
      type: "contact-us",
      sender: email,
      receiver: REACT_APP_EMAIL_RECEIVER,
      subject: `[BookingCampus] Contact Us`,
      content: content || notes,
      metadata: {
        customer_email: email,
        customer_name: name,
        customer_phone_number: phone,
        customer_note: notes,
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
