import { Radio } from "antd";
import styled from "styled-components";
import tw, { theme } from "twin.macro";
import RadioCheckedImage from "images/bc/icons/radio-checked.svg";

const StyledRadio = styled(Radio)`
  &.ant-radio-wrapper {
    .ant-radio-inner {
      ${tw`w-[20.17px] h-[20.17px] border-2 transition-none`}
    }
    .ant-radio-checked {
      .ant-radio-inner {
        border-color: ${theme`colors.bc.primary1`};
        background-color: transparent;
        background-image: url("${RadioCheckedImage}");
        background-position: -3px -3px;
        &:after {
          opacity: 0
        }
      }
    }
    &:hover {
      .ant-radio-inner {
        border-color: ${theme`colors.bc.primary1`};
      }
    }
  }
`;

export default ({ options, value, onChange }) => {
  return (
    <Radio.Group onChange={onChange} value={value}>
      {options.map((option) => (
        <StyledRadio key={option.value} value={option.value}>{option.label}</StyledRadio>
      ))}
    </Radio.Group>
  );
};
