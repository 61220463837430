import { CURRENCY } from "./constants";

export const truncateFullWord = (str, maxLen, separator = " ") => {
  if (str.length <= maxLen) return null;
  return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
};

export const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export function formatCurrency(num) {
  const formatter = Intl.NumberFormat("en", {
    style: "currency",
    currency: CURRENCY,
  });

  return formatter.format(num || 0);
}
